export const URL_SECURE_API = process.env.VUE_APP_API_SECURE_URL
export const URL_LAMBDA_CYBERSOURCE = process.env.VUE_APP_LAMBDA_URL

export const URL_CARDINAL = process.env.VUE_APP_URL_CARDINAL

export const MSG_ERROR_CIP = 'Hubo un error generando tu código CIP, por favor inténtalo luego.'
export const STORES_IMG = {
  BANCO_BBVA: 'bbva-store',
  BANCO_BCP: 'bcp-store',
  BANCO_INTERBANK: 'interbank-store',
  BANCO_SCOTIABANK: 'scotiabank-store',
  BANCO_BANBIF: 'banbif-store',
  CAJA_AREQUIPA: 'arequipa-store',
  EXPRESS_BBVA: 'expressBBVA-store',
  AGENTE_BCP: 'agenteBCP-store',
  WESTERN_UNION: 'western-store',
  MONEYGRAM: 'moneygram-store',
  DISASHOP: 'disashop-store',
  TAMBO: 'tambo-store'
}

export default {
  MSG_ERROR_CIP,
  STORES_IMG
}

export const CYBERSOURCE = {
  sessionExpiryTime: 600 // en segundos
}

export const CYBERSOURCE_STATUS = {
  AUTHENTICATION_FAILED: 'AUTHENTICATION_FAILED',
  AUTHENTICATION_SUCCESSFUL: 'AUTHENTICATION_SUCCESSFUL',
  PENDING_AUTHENTICATION: 'PENDING_AUTHENTICATION',
  COMPLETED: 'COMPLETED',
  NETWORK: [404, 502, 400]
}

export const CYBERSOURCE_INFORMATION_ERROR = {
  SYSTEM_ERROR: 'SYSTEM_ERROR',
  NOT_FOUND: 'Error no encontrado',
  CARD_NOT_FOUND: ' Número de tarjeta no identificada'
}

export const CYBERSOURCE_ECI = {
  SEVEN: '07',
  CERO: '00'
}

export const CYBERSOURCE_AUTHENTICATION_RESULT = {
  FAILURE: '6'
}

export const CYBERSOURCE_VERES_ENROLLED = {
  Y: 'Y',
  N: 'N',
  U: 'U'
}

export const CYBERSOURCE_PARES_STATUS = {
  Y: 'Y'
}

export const ORIGIN_ENVIRONMENT = Object.freeze({
  LIVE: 'live',
  TEST: 'test'
})

export const TITLE_ERROR_MESSAGE = 'No se pudo procesar el pago'
export const ERROR_MESSAGE = {
  CHARGE_NOT_FOUND: {
    text: 'No se pudo procesar el pago. Comuníquese con Culqi o vuelva a intentarlo en otro momento'
  },
  ORDER_GENRATE_TRANSACTION_NOT_FOUND: {
    text: 'Hubo un error generando tu código CIP, por favor inténtalo en otro momento'
  },
  UPDATE_PAYMENT_TYPE_NOT_FOUND: {
    text: 'Hubo un error al actualizar el método ed pago, por favor inténtalo en otro momento'
  }
}
export const MODAL_MESSAGE = {
  CARD_NOT_ENROLLED: {
    title: TITLE_ERROR_MESSAGE,
    text: 'La tarjeta no pudo ser identificada por el banco. Intente otro método de pago u otra tarjeta'
  },
  ISSUER_SYSTEM_ERROR: {
    title: TITLE_ERROR_MESSAGE,
    text: 'Ocurrió un error al procesar el código de seguridad'
  },
  ISSUER_AUTHENTICATION_FAILED: {
    title: TITLE_ERROR_MESSAGE,
    text: 'El banco no pudo autenticar su tarjeta. Intente otro método de pago u otra tarjeta'
  },
  AUTHENTICATION_FAILED: {
    title: TITLE_ERROR_MESSAGE,
    text: 'Ha fallado la autenticación. Intente otro método de pago u otra tarjeta'
  },
  SESSION_EXPIRED: {
    title: 'Han pasado 10 minutos',
    text: 'La sesión expiro, vuelva a intentarlo'
  },
  INVALID_DATA: {
    title: TITLE_ERROR_MESSAGE,
    text: 'Verifique los datos ingresados y vuelva a intentarlo'
  },
  ERROR_NOT_FOUND: {
    title: TITLE_ERROR_MESSAGE,
    text: 'Ocurrió un error inesperado. Comuníquese con Culqi o vuelva a intentarlo en otro momento'
  }
}

export const REGEX_AND_VALUES = {
  EMAIL: /^(([^<>()[\]\\/\\!\\¡\\#\\.,;:\s@\s%']+(\.[^<>()[\]\\/\\!\\¡\\#\\.,;:\s@\s%']+)*)|('.+'))@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]{1,25}\.)+[a-zA-Z]{2,6}))$/,
  PHONE: /^([0-9]{6,12})$/,
  TEXT: /^(?!(.*\s){4,})(?!(.*\s\s\s))(?!.*\s$)[a-zA-ZÀ-ÖØ-öø-ÿ\s]{3,}$/,
  TEXT_ALFA_NUM: /^[A-Za-z0-9\s]+$/,
  NUMERIC: /^[0-9]{3,11}$/,
  CARDNUMBER: {
    VISA: {
      num: /^4|^(4026|417500|4508|4844|491(3|7))/,
      length: 16,
      maskLength: 19,
      mask: '#### #### #### ####'
    },
    MASTERCARD: {
      num: /^5[1-5]|^2[2-7]/,
      length: 16,
      maskLength: 19,
      mask: '#### #### #### ####'
    },
    MAESTRO: {
      num: /^5[0]|^5[6-8]|^6/,
      length: 16,
      maskLength: 19,
      mask: '#### #### #### ####'
    },
    UNIONPAY: {
      num: /^62/,
      length: 16,
      maskLength: 19,
      mask: '#### #### #### ####'
    },
    AMEX: {
      num: /^3[47]/,
      length: 15,
      maskLength: 17,
      mask: '#### ###### ##### ###'
    },
    DISCOVER: {
      num: /^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)/,
      length: 16,
      maskLength: 19,
      mask: '#### #### #### ####'
    },
    DINERS: {
      num: /^36/,
      length: 14,
      maskLength: 19,
      mask: '#### #### #### ####'
    },
    DINERS_CARTE_BLANCHE: {
      num: /^30[0-5]/,
      length: 16,
      maskLength: 19,
      mask: '#### #### #### ####'
    },
    JCB: {
      num: /^35(2[89]|[3-8][0-9])/,
      length: 16,
      maskLength: 19,
      mask: '### #### #### ####'
    }
  },
  CVV: {
    VISA: /^([0-9]{3})$/,
    AMEX: /^([0-9]{4})$/
  },
  NAVIGATOR: {
    USER_AGENT: /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i,
    // eslint-disable-next-line no-useless-escape
    VENDOR: /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\\-|your|zeto|zte\\-/i
  },
  DECIMAL: /^\d{1,5}\.\d{2}$/
}

export const BIN_LIST = [
  '428597', '438168', '455103', '455170',
  '477113', '404293', '414064', '414068',
  '414075', '414089', '414791', '491909',
  '491910', '491911', '491914', '491931',
  '491913', '491912', '491947', '491946',
  '519348', '511578', '512312', '512409',
  '553650'
]

export const TYPE_OPTION = {
  generateCip: 'generateCip',
  updateCip: 'updateCip',
  generateCharge: 'generateCharge'
}

export const VIEW_OPTION = {
  paymentSuccess: 'paymentSuccess',
  cipSuccess: 'cipSuccess',
  cuotealoSuccess: 'cuotealoSuccess',
  initial: 'initial',
  finalStepCip: 'finalStepCip'
}

export const PAYMENT_METHODS_ORDERS = ['bancaMovil', 'billetera', 'agente', 'cuotealo']

export const PAYMENT_METHODS_TOKENS = ['tarjeta', 'yape']

export const PAYMENT_METHODS = [...PAYMENT_METHODS_ORDERS, ...PAYMENT_METHODS_TOKENS]

export const formatDateFallback = {
  date: '--/--/----',
  time: '--:--:--',
  month: '--',
  year: '----'
}

export const AUTHORIZATION = {
  BEARER: 'Bearer'
}

export const DEFAULT_LANG = 'es'

export const SESSION_NAME = 'X-CULQI-EXPRESS-SESSIONID'

export const NAME_SESSION = 'exp_'

export const LINK_ORIGIN = {
  yape: 'YAPE'
}

export const CULQISESSIONID = 'CULQISESSIONID'

export const MASKA_OPTIONS = {
  TEXT: {
    mask: 'Az Az Az Az',
    tokens: {
      A: {
        pattern: /[A-Z]/,
        multiple: false,
        transform: (chr) => chr.toUpperCase()
      },
      z: {
        pattern: /[a-z]/,
        multiple: true,
        transform: (chr) => chr.toLowerCase()
      }
    }
  },
  PHONE: {
    mask: '9## ### ###',
    token: '9:[0-9]',
    repeated: true
  },
  ALL_PHONE: {
    mask: '###########',
    token: '[0-9]{7,11}',
    repeated: true
  },
  NUMBER: {},
  EMAIL: {
    mask: '########################################',
    tokens: {
      '#': {
        pattern: /[A-Za-z0-9.@_A-Za-z0-9]/
      }
    }
  }
}

export const CONFIG_ENCRYPT = {
  keyUsasge: ['encrypt'],
  algorithm: 'aes-256-gcm',
  inputEncoding: 'utf8',
  outputEncoding: 'base64',
  format: 'spki',
  name: 'RSA-OAEP',
  hash: 'SHA-256',
  pem: {
    header: '-----BEGIN PUBLIC KEY-----',
    footer: '-----END PUBLIC KEY-----'
  }
}

export const BASIC_AUTH = 'cFc4MmhqMzQ6VFJibWVLNyQpYSMxTU1rLlhnK25wSFQzWXJLc1YK='

export const PK_RSA = '-----BEGIN PUBLIC KEY-----' +
  'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCvTwaMLR0y4kcDRQPr2+PfoEbr' +
  '9TOkmo/te49OR5P7RRRgGLye3GntvyUl2Gz53JiCNNUFtsH3hV2kkSM2i4T50r1E' +
  'IHqleQ1YJikqa5fqMloJq80riLEMg1HizrLZ2TqgGxJ3QraQGXCaqlznMXrQogCl' +
  'twBHSs5GUdxqP3ytAwIDAQAB' +
  '-----END PUBLIC KEY-----'
