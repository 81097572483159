import axios from 'axios'

export default class ApiConnectorUtil {
  #axiosInstance = axios

  constructor () {
    this.#axiosInstance = axios.create({ headers: {} })
    this.#axiosInstance.interceptors.response.use(
      null,
      this.validateNetworkError
    )
  }

  validateNetworkError (error) {
    if (error && error.message === 'Network Error') {
      return {
        data: { message: '¡Ups! Algo salió mal. Intente mas tarde.' },
        status: 400
      }
    }
    return { data: error.response?.data, status: error.response?.status }
  }

  async headers () {
    return {
      common: {
        'Content-type': 'application/json'
      }
    }
  }

  async get (baseURL, path, headers = {}, params = {}) {
    const head = { ...headers, ...(await this.headers()) }

    this.#axiosInstance.defaults.baseURL = baseURL
    return this.#axiosInstance.get(path, { headers: head, params })
  }

  async post (baseURL, path, payload, headers = {}, params = {}) {
    const head = { ...headers, ...(await this.headers()) }

    this.#axiosInstance.defaults.baseURL = baseURL
    return this.#axiosInstance.post(path, payload, { headers: head, params })
  }
}
