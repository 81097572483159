<template>
  <footer class="footer">
    <template v-if="isCardPage">
      <img
        class="pci"
        src="https://static.culqi.com/v4/v4-checkout/logos/pci.svg"
        alt="Culqi"
      />
    </template>
    <template v-if="isMobile && isMenuPage">
      <img
        class="culqi-brand"
        src="https://static.culqi.com/v4/v4-checkout/logos/culqi-black-m.svg"
        alt="Culqi"
      />
    </template>
    <template v-else>
      <p class="culqi-text">
        Con el respaldo de
        <svg
          width="142"
          height="16"
          viewBox="0 0 142 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_160_7620)">
            <path
              d="M27.1012 5.36489C27.1012 2.79521 25.2269 1.26038 22.0851 1.26038H19.5643C18.9178 1.26038 18.3945 1.78361 18.3945 2.43011V15.0413C18.3945 15.0832 18.4271 15.1157 18.4689 15.1157H20.234C20.2759 15.1157 20.3084 15.0832 20.3084 15.0413V3.83936C20.3084 3.42775 20.6433 3.09287 21.0549 3.09287H22.0828C24.1269 3.09287 25.1641 3.88355 25.1641 5.44163C25.1641 6.99972 24.2781 7.93458 22.2898 8.57177L22.0874 8.63688L26.0873 15.0762C26.1013 15.0971 26.1245 15.1111 26.1501 15.1111H28.2244C28.2826 15.1111 28.3198 15.046 28.2872 14.9971L24.7129 9.28802C26.2524 8.47409 27.0989 7.08577 27.0989 5.36024L27.1012 5.36489Z"
              fill="#1D1D1B"
            />
            <path
              d="M36.1595 15.1134H42.2942C42.336 15.1134 42.3686 15.0809 42.3686 15.039V13.353C42.3686 13.3112 42.336 13.2786 42.2942 13.2786H38.0734C37.4269 13.2786 36.9036 12.7554 36.9036 12.1089V8.91595H41.5802C41.6221 8.91595 41.6546 8.88339 41.6546 8.84153V7.15554C41.6546 7.11368 41.6221 7.08112 41.5802 7.08112H36.9036V4.26494C36.9036 3.61844 37.4269 3.09521 38.0734 3.09521H42.2942C42.336 3.09521 42.3686 3.06265 42.3686 3.02079V1.33247C42.3686 1.29061 42.336 1.25806 42.2942 1.25806H36.1595C35.513 1.25806 34.9897 1.7813 34.9897 2.42779V13.9437C34.9897 14.5902 35.513 15.1134 36.1595 15.1134Z"
              fill="#1D1D1B"
            />
            <path
              d="M54.7798 1.25806H50.8543C50.2078 1.25806 49.6846 1.7813 49.6846 2.42779V13.9437C49.6846 14.5902 50.2078 15.1134 50.8543 15.1134H54.7798C59.1494 15.1134 61.9749 12.374 61.9749 8.1369C61.9749 3.89983 59.1517 1.25806 54.7798 1.25806ZM60.0005 8.19504C60.0005 11.3298 58.0006 13.2786 54.7798 13.2786H52.7682C52.1217 13.2786 51.5985 12.7554 51.5985 12.1089V4.26261C51.5985 3.61612 52.1217 3.09288 52.7682 3.09288H54.7798C57.9517 3.09288 60.0005 5.09514 60.0005 8.19504Z"
              fill="#1D1D1B"
            />
            <path
              d="M69.2653 15.1134H71.0304C71.0723 15.1134 71.1048 15.0809 71.1048 15.039V1.33247C71.1048 1.29061 71.0723 1.25806 71.0304 1.25806H70.3606C69.7142 1.25806 69.1909 1.7813 69.1909 2.42779V15.0367C69.1909 15.0785 69.2235 15.1111 69.2653 15.1111V15.1134Z"
              fill="#1D1D1B"
            />
            <path
              d="M142 5.36489C142 2.79521 140.126 1.26038 136.984 1.26038H134.463C133.817 1.26038 133.293 1.78361 133.293 2.43011V15.1157H135.207V3.83936C135.207 3.42775 135.542 3.09287 135.954 3.09287H136.982C139.026 3.09287 140.063 3.88355 140.063 5.44163C140.063 6.52765 139.612 7.32762 138.64 7.92993C138.244 8.1741 137.763 8.38805 137.186 8.57177L136.984 8.63688L137.835 10.0089C138.835 9.89033 140.235 8.89734 140.235 8.89734C141.375 8.06248 141.995 6.83694 141.995 5.36257L142 5.36489Z"
              fill="#1D1D1B"
            />
            <path
              d="M11.2787 12.5973C11.2647 12.5438 11.2299 12.4996 11.181 12.474C11.1322 12.4507 11.0764 12.4507 11.0275 12.474C9.39737 13.2786 7.80672 13.3647 7.19976 13.3647C4.1487 13.3647 1.93482 11.1903 1.93482 8.19275C1.93482 5.19517 4.14638 3.00455 7.19744 3.00455C7.8044 3.00455 9.39504 3.09059 11.0252 3.89522C11.0741 3.91847 11.1299 3.91847 11.1787 3.89522C11.2275 3.87196 11.2647 3.82778 11.2764 3.77429L11.5926 2.44177C11.6112 2.35806 11.574 2.27201 11.4973 2.2348C10.2834 1.60924 8.83459 1.29065 7.19511 1.29065C2.96037 1.29065 0 4.13009 0 8.19508C0 12.2601 2.96037 15.0809 7.19744 15.0809C8.83692 15.0809 10.2857 14.7623 11.4996 14.1367C11.574 14.0972 11.6136 14.0135 11.595 13.9298L11.2787 12.5973Z"
              fill="#1D1D1B"
            />
            <path
              d="M89.7972 13.9298L89.481 12.5973C89.4693 12.5438 89.4321 12.4973 89.3833 12.474C89.3345 12.4508 89.2786 12.4508 89.2298 12.474C87.5996 13.2786 86.009 13.3647 85.402 13.3647C82.351 13.3647 80.1371 11.1903 80.1371 8.19276C80.1371 5.19519 82.351 3.00223 85.402 3.00223C86.009 3.00223 87.5996 3.08828 89.2298 3.8929C89.2786 3.91616 89.3345 3.91616 89.3833 3.8929C89.4321 3.86965 89.4693 3.82546 89.481 3.77198L89.7972 2.43946C89.8182 2.35807 89.7786 2.2697 89.7042 2.23017C88.4903 1.6046 87.0415 1.28601 85.402 1.28601C81.165 1.28601 78.2046 4.12545 78.2046 8.19276C78.2046 12.2601 81.165 15.0786 85.402 15.0786C87.0415 15.0786 88.4903 14.76 89.7042 14.1344C89.7786 14.0972 89.8182 14.0089 89.7996 13.9275L89.7972 13.9298Z"
              fill="#1D1D1B"
            />
            <path
              d="M125.435 5.36489C125.435 2.79521 123.561 1.26038 120.419 1.26038H117.898C117.252 1.26038 116.729 1.78361 116.729 2.43011V15.0413C116.729 15.0832 116.761 15.1157 116.803 15.1157H118.568C118.61 15.1157 118.642 15.0832 118.642 15.0413V3.83936C118.642 3.42775 118.977 3.09287 119.389 3.09287H120.417C122.461 3.09287 123.498 3.88355 123.498 5.44163C123.498 6.99972 122.612 7.93458 120.624 8.57177L120.421 8.63688L124.421 15.0762C124.435 15.0971 124.458 15.1111 124.484 15.1111H126.558C126.617 15.1111 126.654 15.046 126.621 14.9971L123.047 9.28802C124.586 8.47409 125.433 7.08577 125.433 5.36024L125.435 5.36489Z"
              fill="#1D1D1B"
            />
            <path
              d="M98.6456 6.80673C98.4712 6.98114 98.234 7.07881 97.9851 7.07881H95.5782C95.5108 7.07881 95.4782 6.99742 95.5247 6.95091L102.394 0.0813637C102.504 -0.027935 102.683 -0.027935 102.792 0.0813637L109.661 6.95091C109.708 6.99742 109.675 7.07881 109.608 7.07881H107.201C106.952 7.07881 106.715 6.98114 106.541 6.8044L103.613 3.87659C103.05 3.31382 102.136 3.31382 101.571 3.87659L98.6432 6.8044L98.6456 6.80673ZM98.6456 9.19037C98.4712 9.01595 98.234 8.91828 97.9851 8.91828H95.5782C95.5108 8.91828 95.4782 8.99968 95.5247 9.04619L102.394 15.9157C102.504 16.025 102.683 16.025 102.794 15.9157L109.664 9.04619C109.71 8.99968 109.678 8.91828 109.61 8.91828H107.203C106.955 8.91828 106.717 9.01595 106.543 9.19269L103.615 12.1205C103.052 12.6833 102.138 12.6833 101.573 12.1205L98.6456 9.19269V9.19037Z"
              fill="#1D1D1B"
            />
          </g>
          <defs>
            <clipPath id="clip0_160_7620">
              <rect width="142" height="15.9972" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </p>
    </template>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  props: {
    isMobile: {
      type: Boolean,
      default: false
    },
    mdCurrentPage: {
      type: String,
      default: ''
    }
  },
  computed: {
    isCardPage () {
      return this.mdCurrentPage === 'cardForm'
    },
    isMenuPage () {
      return this.mdCurrentPage === 'cardForm' || this.mdCurrentPage === 'cipView'
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@assets/scss/config/_palette.scss';
@import '@assets/scss/config/core/_breakpoints.scss';

.footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-column: 1 / 2 span;
  z-index: 2;
  .culqi-brand {
    object-fit: contain;
    margin: 32px 0;
   @include from (400px){
      margin: 75px 0;
    }
  }
  p {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 11px;
    color: $black-dark;
    margin: 32px 0;
    svg {
      margin-left: 8px;
    }
    @include from (400px){
      margin: 75px 0;
    }
  }
}
</style>
