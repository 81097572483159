<template>
  <div class="final-step">
    <h2>¡Muchas gracias!</h2>
    <p class="paragraph">
      Si aún no realizas el pago, recuerda que para procesar tu pedido debes
      utilizar tu <strong>código CIP</strong> o <strong>código QR</strong> a
      través de tu medio de pago elegido.
    </p>
    <table-container
      md-class-container="final-step__table-container"
      md-class-table="detail"
      :is-header-present="false"
      :md-tr-list="[
        {
          items: [{text: 'Monto a pagar', colspan: '2'}, {text: data.amount}]
        },
        {
          items: [
            {text: 'Código de pago (CIP)', colspan: '2'},
            {text: data.cip_code}
          ]
        }
      ]"
    />
    <DeadLine md-class="final-step__deadline">
      {{ formatDate() }}
    </DeadLine>
  </div>
</template>

<script>
import { formatDate } from '@src/utils/functions'
import TableContainer from '@components/Molecules/TableContainer.vue'
import DeadLine from '@components/Atoms/DeadLine.vue'

export default {
  name: 'FinalStepCip',
  components: {
    TableContainer,
    DeadLine
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  mdAmount: {
    type: String,
    default: ''
  },
  methods: {
    formatDate () {
      const { date, hours } = formatDate(this.data.expiration_date)
      return `Puedes pagar tu pedido hasta: ${date} ${hours}`
    }
  },
  computed: {}
}
</script>

<style lang="scss">
@import '@assets/scss/config/_palette.scss';
@import '@assets/scss/config/_declare-fonts.scss';

.final-step {
  text-align: center;
  font-family: $FontLexend;
  max-width: 393px;
  margin: 50px auto;
  h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: $black;
    padding: 0 35px;
  }
  .paragraph {
    margin-top: 24px;
    margin-bottom: 31px;
    color: $black-light;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
  &__deadline {
    text-align: left;
  }
  &__table-container {
    padding: 0 10px;
    margin-bottom: 28px;
    .detail {
      tbody {
        td {
          color: $black-light;
          text-align: left;
          &:last-child {
            color: $green;
            font-size: 16px;
            text-align: right;

          }
        }
      }
    }
  }
  &__button {
    margin-top: 57px;
    .button {
      width: 100%;
    }
  }
}
</style>
