<template>
  <div>
    <div v-if="!data.showLink && loadingGetInfo === false">
      <NotFoundYape :errorMessage="data.error" />
    </div>
    <LoaderYape :show="loadingGetInfo" :colorsSpinner="colorSpinners" />
    <div v-if="data.showLink" class="layout">
      <div class="wrapper" id="yape">
        <div class="toolbar-banner toolbar-banner__yape">
          <div class="toolbar-banner__logo">
            <img src="https://static.culqi.com/v4/v4-checkout/logos/yape_lg_M_Express_purple.svg" alt="Yape" />
          </div>
        </div>
        <Header :md-concept="data.concept" :md-amount="data.amount" :md-logo="data.commerce.logo"
          :md-store="data.commerce.name" :is-mobile="config.isMobile" :md-current-page="showPage" />
        <InfoStoreAmount :md-amount="`${data.currencySymbol} ${formatAmountToDecimal}`" :md-store="data.commerce.name"
          :is-mobile="config.isMobile" :md-current-page="showPage" />
        <canvas v-if="showPage === view_option.paymentSuccess" id="canvas"></canvas>
        <div
          :class="['container-wrap', showPage !== 'initial' && showPage !== view_option.cipSuccess && showPage !== view_option.paymentSuccess && 'showMenu']">
          <FinalStepCip v-if="showPage === view_option.cipSuccess" :data="{
            amount: `${data.currencySymbol} ${formatAmountToDecimal}`,
            cip_code: CIPcode,
            expiration_date: CIPexpirationDate
          }" v-on:goInitialPage="goInitialPage" />
          <SuccessPaymentYape v-if="showPage === view_option.paymentSuccess" :paid-at="paidAt"
            :md-amount="`${data.currencySymbol} ${formatAmountToDecimal}`" :md-store="data.commerce.name"
            v-on:goInitialPage="goInitialPage" :is-active="Boolean(showPage === view_option.paymentSuccess)"
            :md-card-number="cardInfo.number" />
          <template v-if="showPage !== view_option.cipSuccess && showPage !== view_option.paymentSuccess">
            <div
              :class="['wrapper-container', { menu: showPage === 'showMenu' }, { 'container-flex': showPage === view_option.cuotealoSuccess }]">
              <div class="wrapper-container__left">
                <div v-if="showPage === 'initial'" class="info-pay">
                  <h2>¡Hola!</h2>
                  <span>Tienes un pago pendiente de
                    <strong>{{ data.commerce.name }}</strong></span>
                  <table-container-amount v-if="openFieldAmount" md-class-container="info-pay__content"
                    @amount="amountEvent" :md-input-disabled="checkoutIsActive || buttonActionClick.initialPage"
                    :md-prefix="data.currencySymbol" :md-currency="data.currency" :md-min-amount="data.amountMinSale"
                    :md-max-amount="data.amountMaxSale" :md-concept="data.concept" />
                  <table-container v-else md-class-container="info-pay__content" md-class-table="detail"
                    :md-header="[{ text: 'Detalle de tu compra', colspan: '3' }]" :md-tr-list="[
                      {
                        class: 'detail-info',
                        items: [{ text: data.concept, colspan: '3' }]
                      },
                      {
                        class: 'detail-total',
                        items: [
                          { text: 'Total', colspan: '2' },
                          { text: data.currencySymbol + ' ' + formatAmountToDecimal, colspan: lengthAmount >= 6 ? '2' : '1' }
                        ]
                      }
                    ]" />
                  <Deadline v-if="data.expirationDate">
                    Recuerda que tienes hasta el
                    <strong>{{ formarDate }}</strong> para pagar tu link o deberás
                    solicitarlo nuevamente.
                  </Deadline>
                </div>
              </div>

              <div class="wrapper-container__right">
                <InitialPage v-if="showPage === 'initial'" :currency="data.currency"
                  :currencySymbol="data.currencySymbol" v-on:submitMethod="submitMethod"
                  :defaultClientEmail="clientEmail"
                  :md-input-disabled="checkoutIsActive || buttonActionClick.initialPage"
                  :md-amout-open-field="{ isRequired: openFieldAmount, isValid: amount.isValid }" />
                <vue-recaptcha v-if="isCatpchaEnabled" ref="recaptcha" @verify="onCaptchaVerified"
                  @expired="onCaptchaExpired" size="invisible" :sitekey="data.captchaPublicKey" />
                <Footer :is-mobile="config.isMobile" :md-current-page="showPage" />
              </div>
            </div>
          </template>
        </div>

      </div>
    </div>
    <Modal v-show="isModalVisible" :title="modalContent.title" :text="modalContent.text"
      :reloadEnabled="modalContent.reloadEnabled" @close="closeModal" />
  </div>
</template>

<script>
/* eslint-disable no-debugger, no-console */
import VueRecaptcha from 'vue-recaptcha'
import { formatMongoDBDate, getPaymentMethod } from '@src/utils/functions'
import logger from '@src/utils/logger'

import IconAlert from '@components/Atoms/icons/IconAlert.vue'
import IconCheck from '@components/Atoms/icons/IconCheck.vue'

import FinalStepCip from '@pages/FinalStepCip.vue'
import InitialPage from '@pages/yape/InitialPage.vue'
import SuccessPaymentYape from '@pages/yape/SuccessPaymentYape.vue'
import LoaderYape from '@components/LoaderYape.vue'
import Modal from '@components/Modal.vue'
import Footer from '@components/Atoms/Footer.vue'
import InfoStoreAmount from '@components/Atoms/InfoStoreAmount.vue'

import TableContainer from '@components/Molecules/TableContainer.vue'
import TableContainerAmount from '@components/Molecules/TableContainerAmount.vue'
import Deadline from '@components/Atoms/DeadLine.vue'

import NotFoundYape from '@pages/yape/NotFoundYape.vue'
import Header from '@components/Header.vue'

import expressApi from '@src/services/express'
import { REGEX_AND_VALUES, TYPE_OPTION, VIEW_OPTION } from '@src/utils/constants.js'

import Culqi3DS from '@src/utils/culqi3DS'
import Culqi from '@src/utils/culqi'
// import { BASIC_AUTH, PK_RSA } from './utils/constants'
// import { encryptWithAESRSA02 } from './utils/encrypt'

export default {
  name: 'CheckoutYape',
  components: {
    Header,
    InitialPage,
    SuccessPaymentYape,
    NotFoundYape,
    VueRecaptcha,
    LoaderYape,
    Modal,
    Footer,
    FinalStepCip,
    TableContainer,
    TableContainerAmount,
    Deadline,
    InfoStoreAmount,
    // eslint-disable-next-line vue/no-unused-components
    IconAlert,
    // eslint-disable-next-line vue/no-unused-components
    IconCheck
  },
  created () {
    window.addEventListener('resize', this.checkScreen)
    this.checkScreen()
  },
  async mounted () {
    this.colorSpinners = [
      { id: 1, color: 'purple-1' },
      { id: 2, color: 'purple-2' },
      { id: 3, color: 'purple-3' }
    ]

    let uri = window.location.href.split('/')
    let length = uri.length
    if (uri[length - 2] === 'pago') {
      const pathName = this.separateFromQuestionMark(uri[length - 1])
      if (pathName.length > 0) {
        this.loadingGetInfo = true
        if (pathName.includes('-')) {
          const [commerceId, linkId] = pathName.split('-')
          this.data.commerceId = commerceId
          this.data.linkId = linkId
        } else {
          this.data.linkCode = pathName
        }
        const payload = {
          linkCode: this.data.linkCode ? this.data.linkCode : undefined,
          isStExpress: true
        }

        const response = await expressApi.getLinkInfo(payload)

        if (response?.data?.info?.data?.origin === 'DASHBOARD' || response?.data?.data?.origin === 'DASHBOARD') {
          this.data.error = 'El código de link no existe o no se encuentra disponible'
        } else {
          if ((!this.oldEnpoints && response.data?.data) || (this.oldEnpoints && response.data?.info)) {
            // Genera el deviceFingerPrintId para antifraude niubiz
            // this.generateDeviceFingerprintId()
            this.data = !this.oldEnpoints ? response.data.data : response.data.info.data
            this.openFieldAmount = this.data?.isOpenAmount
            this.paymentMethod = this.data.paymentMethods[0].id
            const { objMethods, isOrdeMethodPresent, isOnlyOrderMethod } = getPaymentMethod(this.data.paymentMethods)
            this.isOrdeMethodPresent = isOrdeMethodPresent
            this.isOnlyOrderMethod = isOnlyOrderMethod
            this.paymentMethodObject = objMethods
            if (this.isCatpchaEnabled) {
              setTimeout(() => {
                this.$refs.recaptcha.reset()
              }, 5000)
            }
          } else if (response.data?.message || response.data?.user_message) {
            this.data.error = this.oldEnpoints ? response.data.message : response.data.user_message
          } else if (response.error?.message) {
            this.data.error = response.error.message
          }
        }

        this.loadingGetInfo = false
      }
    } else {
      this.data.error = 'Error direccion no existe'
    }
  },
  watch: {
    typeOrderGenerated (method) {
      this.paymentType = method
      if (method !== 'card' && method !== 'yape') {
        this.generateCaptcha(TYPE_OPTION.updateCip)
      }
    },
    checkoutIsActive (value) {
      if (!value && this.culqiInstance) {
        this.culqiInstance.destroy()
      }
    }
  },
  data () {
    return {
      isCatpchaEnabled: process.env.VUE_APP_CAPTCHA_ENABLED === 'true',
      openFieldAmount: false,
      view_option: VIEW_OPTION,
      buttonActionClick: {
        initialPage: false
      },
      orderId: '',
      optionPaid: '',
      isOrdeMethodPresent: false,
      isOnlyOrderMethod: false,
      typeOrderGenerated: '',
      wasObjectCreated: false,
      checkoutIsActive: false,
      generate: {
        token: {},
        order: {}
      },
      tokenWasCreated: false,
      showPage: 'initial', // 'cardForm', // default initial // finalStepCip // paidView
      selectMobile: false,
      clientEmail: '',
      clientLastname: '',
      clientName: '',
      clientTel: '',
      clientOrder: '',
      paymentMethod: null,
      paymentMethodObject: {},
      paymentMethodNew: null,
      typeOption: '',
      cardInfo: {
        token: '',
        typeCard: '',
        feesNumber: '',
        orderId: '',
        number: ''
      },
      config: {
        isMobile: null,
        windowWidth: null
      },
      recaptchaToken: '',
      paidAt: '',
      CIPcode: '',
      CIPexpirationDate: 0,
      transactionId: '',
      data: {},
      loadingGetInfo: false,
      errorMsg: '',
      culqiInstance: '',
      culqiInstance3DS: '',
      stepUpUrl: '',
      referenceId: '',
      showChallenge: false,
      paymentType: '',
      challengeResponseReturned: false,
      token: '',
      cipGenerated: false,
      validForm: true,
      isModalVisible: false,
      deviceFingerPrintId: null,
      deviceFingerPrint: null,
      sessionInterval: null,
      authenticatedWith3DS: false,
      authentication3DS: {
        eci: null,
        xid: null,
        cavv: null,
        protocolVersion: null,
        directoryServerTransactionId: null
      },
      oldEnpoints: false,
      modalContent: {
        title: '',
        text: '',
        reloadEnabled: true
      },
      configDFPId: {
        sasOrgId: null,
        csOrgId: null
      },
      amount: {
        value: '',
        isValid: false
      },
      colorSpinners: []
    }
  },
  methods: {
    handleMessage (event) {
      if (event.origin === window.location.origin) {
        const { parameters3DS, error } = event.data
        const response = event.data
        if (response.loading) {
          /* Mostrar custom loading */
        } else {
          /* Ocultar custom loading */
        }
        if (parameters3DS) {
          this.authentication3DS = {
            eci: parameters3DS.eci,
            xid: parameters3DS.xid,
            cavv: parameters3DS.cavv,
            protocolVersion: parameters3DS.protocolVersion,
            directoryServerTransactionId: parameters3DS.directoryServerTransactionId
          }
          this.loadingGetInfo = true
          // this.payWithCulqi() // this.createCharge()
          this.generateCaptcha(TYPE_OPTION.generateCharge)
        }
        if (error) {
          this.checkoutIsActive = false
          this.buttonActionClick.initialPage = false
        }
      }
    },
    amountEvent ({ amount, checked }) {
      if (this.openFieldAmount) {
        this.amount.value = amount
        this.amount.isValid = checked
        this.data.amount = amount
      }
    },
    culqiFunction () {
      const self = this
      const token = this.culqiInstance.getToken()
      const order = this.culqiInstance.getOrder()
      const error = this.culqiInstance.getError()
      if (token) {
        self.tokenWasCreated = true
        self.token = token
        self.culqiInstance.close()
        self.submitCardInfo(token)
      } else if (order) {
        this.generate.order = window.Culqi.order
        if (this.generate.order.cuotealo) {
          self.culqiInstance.close()
          this.showPage = this.view_option.cuotealoSuccess
        }
      } else {
        if (error && error.merchant_message) {
          logger.error(`Error : ${window.Culqi.error.merchant_message}.`)
        }
      }
    },
    closeCheckout () {
      this.wasObjectCreated = window.Culqi?.wasObjectCreated
      this.lastPickMethod = window.Culqi?.methodValue
      const paymentMethod = ['billetera', 'cuotealo', 'agente', 'bancaMovil']
      const isPaymentMethod = paymentMethod.includes(this.lastPickMethod)
      if (isPaymentMethod && this.wasObjectCreated) {
        this.showPage = this.view_option.cipSuccess
      }
      setTimeout(() => {
        this.checkoutIsActive = false
        this.buttonActionClick.initialPage = false
      }, 500)
      this.resetCaptcha()
    },
    separateFromQuestionMark (text) {
      const regex = /^([^?]+)/
      const match = text.match(regex)
      if (match) {
        const result = match[1].trim()
        return result
      }
      return ''
    },
    methodActive () {
      this.typeOrderGenerated = window.Culqi?.methodValue
      this.CIPcode = this.generate.order?.payment_code
      this.CIPexpirationDate = this.generate.order?.expiration_date
    },
    closeModal () {
      // this.generateDeviceFingerprintId()
      this.checkoutIsActive = false
      this.buttonActionClick.initialPage = false
      this.isModalVisible = false
    },
    openModalIframe () {
      this.showChallenge = true
    },
    closeModalIframe () {
      this.showChallenge = false
    },
    handleContentModal (content, reloadEnabled = true) {
      this.$set(this.$data, 'loadingGetInfo', false)
      this.$set(this.$data, 'modalContent', { ...content, reloadEnabled })
      this.$set(this.$data, 'isModalVisible', true)
    },
    async payWithCulqi () {
      if (this.cardInfo.token) this.createCharge()
    },
    async generateDeviceId () {
      if (this.deviceFingerPrintId === null) {
        this.deviceFingerPrintId = await this.culqiInstance3DS.getDeviceIdCulqi3DS()
      }
    },
    createPayload () {
      const payload = {
        linkId: this.data.linkId,
        commerceId: parseInt(this.data.commerceId),
        typeCard: this.cardInfo.typeCard,
        clientEmail: this.clientEmail,
        token: this.cardInfo.token,
        firstName: this.clientName,
        lastName: this.clientLastname,
        phoneNumber: this.clientTel.split(' ').join(''),
        feesNumber: this.cardInfo.feesNumber,
        orderId: this.cardInfo.orderId,
        captcha: this.recaptchaToken,
        paymentType: !this.oldEnpoints ? 'tarjeta' : this.paymentType,
        authentication: this.authentication3DS,
        deviceFingerPrintId: this.deviceFingerPrintId
      }
      if (this.openFieldAmount) {
        payload.amount = this.data.amount
      }
      return payload
    },
    handleReviewResponse () {
      this.loadingGetInfo = false
      if (this.isCatpchaEnabled) {
        this.$refs.recaptcha.reset()
        this.$refs.recaptcha.execute()
      }
      this.culqiInstance3DS.initCulqi3DS(this.cardInfo.token)
    },
    handleSuccessfulResponse (response) {
      if (this.culqiInstance3DS) {
        this.culqiInstance3DS.reset()
      }
      this.paidAt = response.data.result.creation_date
      this.showPage = this.view_option.paymentSuccess
      this.loadingGetInfo = false
      this.checkoutIsActive = false
    },
    handleErrorResponse () {
      this.culqiInstance3DS.reset()
      this.loadingGetInfo = false
      this.checkoutIsActive = false
      this.buttonActionClick.initialPage = false
      this.authenticatedWith3DS = false
    },
    handleResponse (response) {
      const isOldEndpointsAndStatus200 = this.oldEnpoints && response.status === 200
      const isNewEndpointsAndStatus201 = !this.oldEnpoints && response.status === 201
      const isSuccessfulResponse = isOldEndpointsAndStatus200 || isNewEndpointsAndStatus201

      if (response.status === 200 && response.data.result.action_code === 'REVIEW') {
        this.handleReviewResponse()
      } else if (isSuccessfulResponse) {
        this.handleSuccessfulResponse(response)
      } else {
        this.handleErrorResponse()
      }
    },
    async createCharge () {
      await this.generateDeviceId()
      const payload = this.createPayload()
      const pk = this.data.pk
      const response = await expressApi.createCharge(pk, payload)
      this.handleResponse(response)
    },
    goInitialPage () {
      this.challengeResponseReturned = false
      this.showPage = 'initial'
      this.paymentMethod = this.data.paymentMethods[0].id
      this.clientEmail = ''
      this.clientName = ''
      this.clientLastname = ''
      this.clientTel = ''
      this.CIPcode = ''
      this.CIPexpirationDate = 0
      this.paidAt = ''
      this.authenticatedWith3DS = false
      this.checkoutIsActive = false
      this.loadingGetInfo = false
      this.cipGenerated = false
      this.authentication3DS = {
        eci: null,
        xid: null,
        cavv: null,
        protocolVersion: null,
        directoryServerTransactionId: null
      }
      window.location.reload()
    },
    submitMethod ({ email, tel, name, lastname, order }) {
      this.buttonActionClick.initialPage = true
      this.clientEmail = email
      this.clientTel = tel
      this.clientName = name
      this.clientLastname = lastname
      this.clientOrder = order
      if (this.isOrdeMethodPresent) {
        this.generateCaptcha(TYPE_OPTION.generateCip)
      } else {
        this.loadingGetInfo = true
        this.initCulqiCheckout()
        this.initCulqi3DS()
      }
    },
    initCulqiCheckout () {
      this.culqiInstance = new Culqi({
        codigoComercio: this.data.pk,
        settingsParams: {
          comerceName: this.data.commerce.name,
          amount: this.data.amount,
          currency: this.data.currency,
          logo: this.data.commerce.logo,
          orderId: this.orderId,
          style: {
            logo: 'https://static.culqi.com/v4/v4-checkout/logos/yape_lg_M_Express_white.svg',
            bannerColor: '#742384',
            buttonBackground: '#10CBB4',
            menuColor: '#742384',
            linksColor: '#742384',
            buttonText: 'Pagar',
            buttonTextColor: '#FFFFFF',
            priceColor: '#742384'
          }

        },
        clientParam: {
          email: this.clientEmail
        },
        paymentMethods: this.paymentMethodObject
      })
      this.culqiInstance.open()
        .then(() => {
          window.Culqi.culqi = () => this.culqiFunction()
          window.Culqi.methodActive = () => this.methodActive()
          window.Culqi.closeCheckout = () => this.closeCheckout()
          this.checkoutIsActive = true
          this.loadingGetInfo = false
        })
        .catch(() => {
          this.buttonActionClick.initialPage = false
          this.checkoutIsActive = false
          this.$toast.error('Error al abrir Culqi checkout, por favor vuelve a intentarlo más tarde', {
            timeout: 3000,
            icon: IconAlert
          })
        })
      // this.initCulqi3DS()
    },
    initCulqi3DS () {
      this.culqiInstance3DS = new Culqi3DS({
        codigoComercio: this.data.pk,
        settings: {
          currency: this.data.currency,
          amount: this.data.amount,
          url: process.env.VUE_APP_ST_EXPRESS,
          email: this.clientEmail
        }
      })
      this.culqiInstance3DS.mounted(this.handleMessage)
    },
    generateCip () {
      if (this.isCatpchaEnabled) {
        this.$refs.recaptcha.execute()
      }
    },
    resetCaptcha () {
      if (this.isCatpchaEnabled) {
        this.$refs.recaptcha.reset()
      }
    },
    generateCaptcha (type) {
      this.typeOption = type
      if (this.isCatpchaEnabled) {
        this.$refs.recaptcha.reset()
        this.$refs.recaptcha.execute()
      } else {
        this.onCaptchaVerified('captcha-test')
      }
    },
    generateLink () {
      this.showPage = 'finalStepCip'
    },
    submitCardInfo (token) {
      this.cardInfo = {
        token: token.id,
        typeCard: token.iin.card_brand,
        feesNumber: token?.metadata?.installments || 1,
        orderId: this.clientOrder,
        number: token.card_number
      }
      this.loadingGetInfo = true
      this.checkoutIsActive = true
      this.generateCaptcha(TYPE_OPTION.generateCharge)
    },
    async updateCIPAndCreateTrx () {
      const pk = this.data.pk
      const payload = {
        captcha: this.recaptchaToken,
        paymentType: this.typeOrderGenerated,
        orderId: this.orderId,
        linkId: this.data.linkId,
        client: {
          email: this.clientEmail,
          firstName: this.clientName,
          lastName: this.clientLastname,
          phoneNumber: this.clientTel.split(' ').join('')
        }
      }

      const response = await expressApi.updateOrderAndTransaction(pk, payload)

      if (response.data) {
        this.transactionId = response.data.transactionId
      } else {
        logger.error('Ocurrió un error, por favor vuelve a intentarlo más tarde')
      }
    },
    async updatePaymentMethodCIP () {
      this.loadingGetInfo = true
      const pk = this.data.pk
      const payload = {
        transactionId: this.transactionId,
        captcha: this.recaptchaToken,
        paymentType: this.typeOrderGenerated
      }
      await expressApi.updatePaymentType(pk, payload)

      this.loadingGetInfo = false
    },
    async generateCipWithOutConfirm () {
      this.loadingGetInfo = true
      const payload = {
        linkId: this.data.linkId,
        commerceId: parseInt(this.data.commerceId),
        clientEmail: this.clientEmail,
        captcha: this.recaptchaToken,
        firstName: this.clientName,
        lastName: this.clientLastname,
        phoneNumber: this.clientTel.split(' ').join(''),
        orderId: this.clientOrder
      }
      const pk = this.data.pk
      if (this.openFieldAmount) {
        payload.amount = this.data.amount
      }
      const response = await expressApi.createOrderWithOutConfirm(pk, payload)
      if (response.data) {
        this.orderId = response.data.orderId
        this.cipGenerated = true
      } else {
        this.cipGenerated = false
      }
      if (this.isOnlyOrderMethod && !this.cipGenerated) {
        this.loadingGetInfo = false
        this.cipGenerated = false
        this.buttonActionClick.initialPage = false
        this.checkoutIsActive = false
        this.$toast.error('Ups! hubo un error, por favor vuelve a intentarlo más tarde', {
          timeout: 3000,
          icon: IconAlert
        })
        return
      }
      this.initCulqiCheckout()
      this.initCulqi3DS()
    },
    async onCaptchaVerified (recaptchaToken) {
      if (!recaptchaToken) {
        this.loadingGetInfo = false
        this.$refs.recaptcha.reset()
        return
      }
      this.recaptchaToken = recaptchaToken
      await this.onValidateTransaction()
      this.token = null
    },
    async onValidateTransaction () {
      if (this.typeOption === TYPE_OPTION.generateCip) {
        await this.generateCipWithOutConfirm()
      }
      if (this.typeOption === TYPE_OPTION.updateCip) {
        if (this.transactionId) {
          await this.updatePaymentMethodCIP()
        } else {
          await this.updateCIPAndCreateTrx()
        }
      }
      if (this.typeOption === TYPE_OPTION.generateCharge) {
        if (this.paymentMethodNew) {
          this.paymentMethod = await this.paymentMethodNew
        }
        this.payWithCulqi()
      }
      this.typeOption = ''
    },
    async onCaptchaExpired () {
      this.loadingGetInfo = false
      if (!this.mobileAndTabletCheck()) {
        this.$refs.recaptcha.reset()
      }
    },
    mobileAndTabletCheck: function () {
      let check = true
      const { USER_AGENT, VENDOR } = REGEX_AND_VALUES.NAVIGATOR
      const windowNavigator = navigator.userAgent || navigator.vendor || window.opera
      if (USER_AGENT.test(windowNavigator) || VENDOR.test(windowNavigator.substr(0, 4))) {
        check = true
      }
      return check
    },
    checkScreen () {
      this.config.windowWidth = window.innerWidth
      if (this.config.windowWidth <= 650) {
        this.config.isMobile = true
        return
      }
      this.config.isMobile = false
    },
    _handleClickEnableCopy (isValid) {
      if (isValid) {
        this.$toast.success('Código copiado', {
          timeout: 3000,
          icon: IconCheck
        })
      } else {
        this.$toast.error('No se pudo copiar el código', {
          timeout: 3000,
          icon: IconAlert
        })
      }
    }
  },
  computed: {
    paymentSeleted () {
      return this.data.paymentMethods.filter(({ id }) => this.paymentMethod === id)[0]
    },
    formatAmountToDecimal () {
      return (this.data.amount / 100).toFixed(2)
    },
    lengthAmount () {
      return this.formatAmountToDecimal.length
    },
    formarDate () {
      const { date } = formatMongoDBDate(this.data.expirationDate)
      return date
    }
  }
}
</script>

<style lang="scss">
@import '@assets/scss/yape.scss';
</style>
