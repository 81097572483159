import { STORES_IMG, PAYMENT_METHODS_ORDERS, PAYMENT_METHODS_TOKENS, formatDateFallback, DEFAULT_LANG, AUTHORIZATION, ORIGIN_ENVIRONMENT, BASIC_AUTH, PK_RSA } from './constants'
import moment from 'moment-timezone'
import Notify from 'notify-zh'
import { encryptWithAESRSA02 } from './encrypt'

export const formatDate = (dateTimeStamp) => {
  const timestampFormat = dateTimeStamp ? moment.unix(dateTimeStamp).utc() : moment.utc()
  const fullDate = moment.tz(timestampFormat, 'America/Lima')
  const date = fullDate.format('DD/MM/YYYY')
  const hours = fullDate.format('HH:mm:ss')
  const day = fullDate.format('DD')
  const mount = fullDate.format('MM')
  const year = fullDate.format('YYYY')
  return {
    day, mount, year, hours, date
  }
}

export const formatMongoDBDate = (date) => {
  if (!date) return formatDateFallback

  const dateValid = typeof date === 'string' ? new Date(date) : date

  const dateFormatted = dateValid.toLocaleDateString('es-ES', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  })

  const timeFormatted = dateValid.toLocaleTimeString('es-ES', {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit'
  })

  const month = dateValid.toLocaleDateString('es-ES', {
    month: 'long'
  })

  const year = dateValid.toLocaleDateString('es-ES', {
    year: 'numeric'
  })

  return {
    date: dateFormatted,
    time: timeFormatted,
    month,
    year
  }
}

export const getCurrentDate = () => {
  const currentDate = moment.tz('America/Lima')
  const year = currentDate.format('YYYY')
  const month = currentDate.format('MM')
  const day = currentDate.format('DD')
  const hour = currentDate.format('HH')
  return { year, month, day, hour }
}

export const getStepsToPay = (paymentMethod, currency, cipCode) => {
  let stepList = []
  let brandList = []
  if (paymentMethod === 2) {
    stepList = [
      'Indica que vas a realizar un pago a la empresa PAGOEFECTIVO.',
      `Indica que realizarás el pago a PAGOEFECTIVO ${currency}.`,
      `Indica el código CIP: ${cipCode} y el importe a pagar.`
    ]
    brandList = [
      STORES_IMG.EXPRESS_BBVA,
      STORES_IMG.AGENTE_BCP,
      STORES_IMG.BANCO_INTERBANK,
      STORES_IMG.BANCO_SCOTIABANK,
      STORES_IMG.WESTERN_UNION,
      STORES_IMG.MONEYGRAM,
      STORES_IMG.DISASHOP,
      STORES_IMG.TAMBO
    ]
  } else if (paymentMethod === 3) {
    stepList = [
      'Selecciona la opción Pago de Servicios.',
      'Busca y selecciona la institución/empresa PAGOEFECTIVO.',
      `Selecciona PAGOEFECTIVO ${currency}.`,
      `Ingresa tu código CIP: ${cipCode} y sigue los pasos.`
    ]
    brandList = [
      STORES_IMG.BANCO_BBVA,
      STORES_IMG.BANCO_BCP,
      STORES_IMG.BANCO_INTERBANK,
      STORES_IMG.BANCO_SCOTIABANK,
      STORES_IMG.BANCO_BANBIF,
      STORES_IMG.CAJA_AREQUIPA
    ]
  } else if (paymentMethod === 4) {
    stepList = [
      'Indica que vas a realizar un pago a la empresa PAGOEFECTIVO.',
      `Indica que realizarás el pago a PAGOEFECTIVO ${currency}.`,
      `Indica el código CIP: ${cipCode} y el importe a pagar.`
    ]
    brandList = [
      STORES_IMG.BANCO_BBVA,
      STORES_IMG.BANCO_BCP,
      STORES_IMG.BANCO_INTERBANK,
      STORES_IMG.BANCO_SCOTIABANK,
      STORES_IMG.BANCO_BANBIF,
      STORES_IMG.CAJA_AREQUIPA
    ]
  }
  return [stepList, brandList]
}

export const enableCopy = (text) => {
  try {
    const el = document.createElement('textarea')
    el.value = text
    el.setAttribute('readonly', '')
    el.style.position = 'absolute'
    el.style.left = '-9999px'
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
    return true
  } catch (error) {
    return false
  }
}

export const getPaymentMethod = (paymentMethods) => {
  let objMethods = {}
  let isOrdeMethodPresent = false
  let isOnlyOrderMethod = true
  paymentMethods.forEach(({ alias }) => {
    if (alias === 'cash') alias = 'bancaMovil'
    if (alias === 'card') alias = 'tarjeta'
    if (!isOrdeMethodPresent) {
      isOrdeMethodPresent = PAYMENT_METHODS_ORDERS.some((method) => alias === method)
    }
    if (isOnlyOrderMethod) {
      isOnlyOrderMethod = !PAYMENT_METHODS_TOKENS.some((method) => alias === method)
    }
    objMethods = {
      ...objMethods,
      [alias]: true
    }
  })
  if (!objMethods.yape) {
    objMethods = {
      ...objMethods,
      yape: false
    }
  }
  if (!objMethods.tarjeta) {
    objMethods = {
      ...objMethods,
      tarjeta: false
    }
  }
  return { objMethods, isOrdeMethodPresent, isOnlyOrderMethod }
}

const extractNumericString = str => str.replace(/[^0-9.]/g, '')

const splitNumericString = numericStr => {
  const [integerPart = '0', decimalPart = ''] = numericStr.split('.')
  return [integerPart, decimalPart]
}

const removeCommas = str => str.replace(/,/g, '')

export const formaAmount = (formattedString) => {
  if (!formattedString) return 0
  const numericString = extractNumericString(formattedString)
  const [integerPart, decimalPart] = splitNumericString(numericString)

  const integerWithoutCommas = removeCommas(integerPart)
  const result = `${integerWithoutCommas}${decimalPart}`
  return parseInt(result, 10)
}

export const formatAmountToDecimal = (amount, useCommas = true) => {
  const _amount = amount.toString()
  if (amount === 0) return 0
  const decimal = _amount.substring(_amount.length - 2, _amount.length)
  const _int = _amount
    .substring(0, _amount.length - 2)
    .replace(/\B(?=(\d{3})+(?!\d))/g, useCommas ? ',' : '')

  return `${_int}.${decimal}`
}

export const getEnvironmentFronURL = () => {
  let env = ORIGIN_ENVIRONMENT.TEST
  const urlParams = new URLSearchParams(window.location.search)
  env = urlParams.get('env')
  if (env === null) {
    env = ORIGIN_ENVIRONMENT.LIVE
  }
  return env
}

export const getLang = () => {
  const lang = DEFAULT_LANG
  return lang
}

export const setHeaders = async (pk) => {
  const headers =
  {
    Authorization: `${AUTHORIZATION.BEARER} ${await generateToken()}`,
    'X-CULQI-SESSION-EXPRESS': null,
    'X-CULQI-LANG': getLang(),
    'X-CULQI-ENV': getEnvironmentFronURL(),
    'X-API-KEY': `${pk}`
  }
  return headers
}

export const setHeadersWithOutAuthorization = async () => {
  const headers =
  {
    Authorization: `${AUTHORIZATION.BEARER} ${await generateToken()}`,
    'X-CULQI-LANG': getLang(),
    'X-CULQI-ENV': getEnvironmentFronURL()
  }
  return headers
}

export const generateToken = async () => {
  const dataRsa = await encryptWithAESRSA02(BASIC_AUTH, PK_RSA)

  const { encryptedData, encryptedKey, encryptedIv, tag } = dataRsa
  const auth = `${encryptedKey}.${encryptedIv}.${tag}.${encryptedData}`

  return auth
}

/*
export const getSessionId = async () => {
  const metaElement = document.querySelector(`meta[name="${CULQISESSIONID}"]`)
  return metaElement ? metaElement.content : null

  // return null // 'DrlioLMadU2vCsV-Z6iWT'
}
*/
export const showNotifyError = (message) => {
  return Notify.error({
    message,
    option: {
      time: 5000
    }
  })
}
