<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1003_21596)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.6334 1.0908C13.179 -0.363598 10.821 -0.363601 9.36658 1.0908L1.0908 9.36658C-0.363598 10.821 -0.363601 13.179 1.0908 14.6334L9.36658 22.9092C10.821 24.3636 13.179 24.3636 14.6334 22.9092L22.9092 14.6334C24.3636 13.179 24.3636 10.821 22.9092 9.36658L14.6334 1.0908ZM10.2479 6.414L10.8066 13.1176H13.1765L13.7182 6.414H10.2479ZM10.0956 15.8938C10.0956 16.4355 10.2761 16.887 10.6373 17.2481C10.9984 17.6092 11.4611 17.7898 12.0254 17.7898C12.5671 17.7898 13.0129 17.6092 13.3627 17.2481C13.7239 16.887 13.9044 16.4355 13.9044 15.8938C13.9044 15.3521 13.7239 14.9007 13.3627 14.5396C13.0129 14.1784 12.5671 13.9979 12.0254 13.9979C11.4611 13.9979 10.9984 14.1784 10.6373 14.5396C10.2761 14.9007 10.0956 15.3521 10.0956 15.8938Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_1003_21596">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'IconAlert'
}
</script>
