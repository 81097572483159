var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"final-step"},[_c('h2',[_vm._v("¡Muchas gracias!")]),_vm._m(0),_c('table-container',{attrs:{"md-class-container":"final-step__table-container","md-class-table":"detail","is-header-present":false,"md-tr-list":[
      {
        items: [{text: 'Monto a pagar', colspan: '2'}, {text: _vm.data.amount}]
      },
      {
        items: [
          {text: 'Código de pago (CIP)', colspan: '2'},
          {text: _vm.data.cip_code}
        ]
      }
    ]}}),_c('DeadLine',{attrs:{"md-class":"final-step__deadline"}},[_vm._v(" "+_vm._s(_vm.formatDate())+" ")])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"paragraph"},[_vm._v(" Si aún no realizas el pago, recuerda que para procesar tu pedido debes utilizar tu "),_c('strong',[_vm._v("código CIP")]),_vm._v(" o "),_c('strong',[_vm._v("código QR")]),_vm._v(" a través de tu medio de pago elegido. ")])
}]

export { render, staticRenderFns }