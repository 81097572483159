<template>
  <div class="wrapper" id="yape">
    <div class="toolbar-banner toolbar-banner__yape">
      <div class="toolbar-banner__logo">
        <img src="https://static.culqi.com/v4/v4-checkout/logos/yape_lg_M_Express_purple.svg" alt="Yape" />

      </div>
    </div>
    <div class="layout-404 layout-yape">
      <div class="container-paragraph">
        <div class="title" v-if="show404">
          <span class="error">
            Esta dirección no existe.
          </span>
        </div>
        <div class="subtitle" v-else>
          {{ startMessage[0] }} <span class="color-yape-light">{{ startMessage[1] }}</span>
          <br />
          <p v-if="endMessage === ''">
            y tu pago no se <span class="color-green">procesó</span>. <br />

          </p>
          <span v-html="endMessage">
          </span>
        </div>
      </div>
      <img class="image image-yape" :src=srcImage alt="error 404">
    </div>
  </div>
</template>

<script>
export default {
  name: 'not-found',
  props: {
    errorMessage: String
  },
  data () {
    return {
      items: [
        { message: 'La URL no tiene el formato correcto (https://express.culqi.com/pago/LINK_CODE)' }
      ]
    }
  },
  computed: {
    show404: function () {
      switch (this.errorMessage) {
        case 'El link ha superado su límite de usos':
        case 'Error el link se encuentra deshabilitado.':
        case 'El link se encuentra deshabilitado':
        case 'El link ha expirado':
        case 'Error el link se encuentra expirado.':
        case 'Error el link excedio el limite de usos':
        case 'No se encuentra el link.':
        case 'El código de link no existe o no se encuentra disponible':
          return false
        default:
          return true
      }
    },
    startMessage: function () {
      switch (this.errorMessage) {
        case 'Error el link excedio el limite de usos':
          return ['Este link alcanzó ', 'el límite de usos permitidos.']
        case 'Error el link se encuentra deshabilitado.':
          return ['Este link ', 'está deshabilitado.']
        case 'Error el link se encuentra expirado.':
          return ['Este link ', 'ha expirado.']
        default:
          return ['Este link ', 'no existe.']
      }
    },
    endMessage: function () {
      switch (this.errorMessage) {
        case 'Error el link excedio el limite de usos':
        case 'Error el link se encuentra deshabilitado.':
        case 'Error el link se encuentra expirado.':
          return 'Comunícate con el negocio y solicita un nuevo link.'
        case 'No se encuentra el link.':
        case 'El código de link no existe o no se encuentra disponible':
          return 'Por favor, comunícate con el negocio y solicita un nuevo link.'
        default:
          return this.errorMessage
      }
    },
    srcImage: function () {
      switch (this.errorMessage) {
        case 'Error el link excedio el limite de usos':
          return '/img/yape/error/Link-alert.svg'
        case 'Error el link se encuentra deshabilitado.':
          return '/img/yape/error/Link-cancel.svg'
        case 'Error el link se encuentra expirado.':
          return '/img/yape/error/Link-outtime.svg'
        case 'Error direccion no existe':
          return '/img/yape/error/error_not_found.svg'
        default:
          return '/img/yape/error/Link-search.svg'
      }
    }
  }

}
</script>

<style lang="scss" scoped>
.layout-404 {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
  height: 100vh;
  background: rgba(0, 0, 0, .9);
  overflow: hidden;
}

.layout-yape {
  background: rgba(255, 255, 255, 1);
  height: 68vh;

  .subtitle {
    color: #2C2044;
    font-weight: 900;
    max-width: 540px;
  }
}

.close {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid #7C7C7C;
  box-sizing: border-box;

  img {
    margin: 0;
    align-self: center;
  }
}

.container-paragraph {
  padding: 0 10%;
}

.title {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  color: #000;
  margin-top:-12px;
  @media(min-width:768px){
  margin-top:-64px;
  }
}

.subtitle, .error {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 30px;
  color: #FFFFFF;

  span {
    font-family: 'Lexend';
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
  }
  line-height: 30px;
}

.error{
  font-size:24px;
  color:#000;
  font-weight: 700;
  font-style: normal;
}
.image {
  align-self: flex-end;
  margin-top: 50px;
}
.image-yape{
  align-self: center;
  @media (min-width: 650px) {
    align-self: flex-end;
    margin-right: 88px;
  }
}
.image-error{
  position: absolute;
  top:-7px;
  right:-48px;
  @media(min-width:768px){
    position:absolute;
    top:-7px;
    right:-48px;
  }
}
.color-yape-light{
  display:block;
  position: relative;
  width:111px;
  @media(min-width:768px){
        display: inline;

  }
}
// .steps-list {
//   font-size: 15px;
//   line-height: 1.57;
//   font-weight: normal;
//   padding: 0 20px;
// }
@import '@assets/scss/yape.scss';
</style>
