<template>
  <div :class="['deadline', mdClass]">
    <div class="deadline-icon">
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11 17.5C11 16.9477 11.4477 16.5 12 16.5C12.5523 16.5 13 16.9477 13 17.5C13 18.0523 12.5523 18.5 12 18.5C11.4477 18.5 11 18.0523 11 17.5Z"
          fill="#7D5507"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12 3.25C6.89137 3.25 2.75 7.39137 2.75 12.5C2.75 17.6086 6.89137 21.75 12 21.75C17.1086 21.75 21.25 17.6086 21.25 12.5C21.25 7.39137 17.1086 3.25 12 3.25ZM1.25 12.5C1.25 6.56294 6.06294 1.75 12 1.75C17.9371 1.75 22.75 6.56294 22.75 12.5C22.75 18.4371 17.9371 23.25 12 23.25C6.06294 23.25 1.25 18.4371 1.25 12.5ZM12 6.75C12.4142 6.75 12.75 7.08579 12.75 7.5L12.75 14.5C12.75 14.9142 12.4142 15.25 12 15.25C11.5858 15.25 11.25 14.9142 11.25 14.5L11.25 7.5C11.25 7.08579 11.5858 6.75 12 6.75Z"
          fill="#7D5507"
        />
      </svg>
    </div>
    <p class="deadline-paragraph">
      <slot />
    </p>
  </div>
</template>

<script>
export default {
  name: 'Deadline',
  props: {
    mdClass: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@assets/scss/config/_palette.scss';

.deadline {
  border-radius: 4px;
  padding: 8.5px 12px;
  background-color: $yellow-light;
  display: flex;
  align-items: center;
  &-icon {
    margin-right: 13.25px;
    svg {
      width: 21.5px;
      height: 21.5px;
    }
  }
  &-paragraph {
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    color: $yellow-dark;
  }
}
</style>
