<template>
  <div :class="['form__row dobble']">
    <div class="form__row__col">
      <div class="form__group">
        <label class="label-text" :for="mdId">{{ mdLabel }}</label>
        <div :class="['form__group-input']">
          <div v-if="mdPrefix" class="prefix">{{ mdPrefix }}</div>
          <input
            :id="mdId"
            :class="['input-decimal', checked, mdClass]"
            type="text"
            placeholder="0.00"
            :ref="nameInput !== '' ? nameInput : mdId"
            v-model='formattedValue'
            @keydown='handleKeyDown'
            @input='handleInput($event.target.value, $event.target)'
            @blur='handleBlur'
            @focus="handleFocus"
            :disabled="mdInputDisabled"
            maxlength="10"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatAmountToDecimal } from '@src/utils/functions'

export default {
  name: 'DecimalInput',
  props: {
    mdInputDisabled: {
      type: Boolean,
      default: false
    },
    mdPrefix: {
      type: String,
      default: '$'
    },
    mdLabel: {
      type: String,
      default: ''
    },
    mdId: {
      type: String,
      default: ''
    },
    nameInput: {
      type: String,
      default: ''
    },
    initialValue: {
      type: String,
      default: ''
    },
    mdMinAmount: {
      type: Number,
      required: true
    },
    mdMaxAmount: {
      type: Number,
      required: true
    },
    mdClass: {
      type: [String, Function],
      default: ''
    }
  },
  data () {
    return {
      value: this.initialValue || '',
      formattedValue: this.initialValue || '',
      keys: ['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete'],
      keysNotAllow: ['Unidentified', '-', '+', 'e', 'E'],
      checked: ''
    }
  },
  watch: {
    formattedValue (value) {
      const isValid = this.isValueInRange(value)
      const isNotValueEmpty = value !== '' && value !== undefined
      this.checked = isNotValueEmpty ? isValid ? 'valid' : 'invalid' : ''
      if (value.split('.').length > 2 || value.split(',').length > 2) {
        this.checked = 'invalid'
      }
      this.$emit('amountValue', { value, isValid: this.checked === 'valid', isNotValueEmpty })
    }
  },
  methods: {
    handleKeyDown (event) {
      if (this.formattedValue.includes('.') && event.key === '.') {
        event.preventDefault()
        return
      }
      if (this.keysNotAllow.includes(event.key)) {
        event.preventDefault()
        return
      }
      if (!this.isValidKey(event.key)) {
        event.preventDefault()
        return
      }
      if (event.key === 'Backspace') {
        this.handleBackspace()
        return
      }
      if (!this.formattedValue.includes('.')) {
        if (this.formattedValue.replace(/\D/g, '').length >= 5) {
          if (!this.keys.includes(event.key)) {
            event.preventDefault()
          }
        }
      }
    },
    handleInput (value, target) {
      const cursorPosition = target.selectionStart
      const sanitizedValue = value.replace(/[^\d.]/g, '')

      const [integerPart, decimalPart] = sanitizedValue.split('.')

      const removeDigitAtCursor = (number, digit) => {
        const stringArray = number.split('')
        stringArray.splice(cursorPosition - 1, 1)
        target.focus()
        requestAnimationFrame(() => {
          target.setSelectionRange(cursorPosition - digit, cursorPosition - digit)
        })
        return stringArray.join('')
      }

      const limitedIntegerPart = integerPart.length > 5 ? removeDigitAtCursor(integerPart, 1) : integerPart

      const formatDecimal = () => {
        const indexDot = value.indexOf('.') + 1
        const number = cursorPosition > indexDot ? 0 : 1
        removeDigitAtCursor(decimalPart, number)
        return decimalPart.slice(0, 2)
      }
      if (decimalPart !== undefined) {
        const limitedDecimalPart = decimalPart.length > 2 ? formatDecimal() : decimalPart
        this.formattedValue = `${limitedIntegerPart || '0'}.${limitedDecimalPart}`
      } else {
        this.formattedValue = limitedIntegerPart || ''
      }
    },
    handleBlur () {
      if (!this.formattedValue.includes('.') && this.formattedValue !== '') {
        this.formattedValue += '.00'
      } else {
        if (this.formattedValue !== '') {
          const parts = this.formattedValue.split('.')
          if (parts[1].length === 0) {
            this.formattedValue += '00'
          }
          if (parts[1].length === 1) {
            this.formattedValue += '0'
          }
          if (parts[1].length >= 3) {
            this.formattedValue = this.formattedValue.slice(0, -(parts[1].length - 2))
          }
        }
      }
      const number = 6
      if (this.formattedValue !== '' && this.formattedValue.length > number) {
        if (!this.formattedValue.includes(',')) {
          const thousands = []
          let valueRef = this.formattedValue
          while (valueRef.length > number) {
            thousands.unshift(valueRef.slice(-number))
            valueRef = valueRef.slice(0, -number)
          }
          thousands.unshift(valueRef)
          this.formattedValue = thousands.join(',')
        }
      }
      this.$emit('input', this.value)
    },
    handleFocus () {
      this.formattedValue = this.formattedValue.replace(/,/g, '')
    },
    isValidKey (key) {
      return (/^[0-9.]$/.test(key) || this.keys.includes(key))
    },
    formatValue () {
      if (this.formattedValue === '') {
        this.value = ''
      } else {
        this.value = parseFloat(this.formattedValue).toFixed(2)
        this.formattedValue = this.value
      }
    },
    handleBackspace () {
      if (this.value.length > 0) {
        this.value = this.value.slice(0, -1)
      }
    },
    isValueInRange (value) {
      const numericValue = parseFloat(value.replace(/,/g, ''))
      return (
        !isNaN(numericValue) &&
        numericValue >= formatAmountToDecimal(this.mdMinAmount, false) &&
        numericValue <= formatAmountToDecimal(this.mdMaxAmount, false)
      )
    }
  }
}
</script>

<style>
  .form__group {
    margin-bottom: 8px !important
  }
</style>
