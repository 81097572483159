const baseURLCulqi3DS = process.env.VUE_APP_CULQI_3DS_URL

export default class Culqi3DS {
  #publicKey

  constructor ({ codigoComercio, settings }) {
    if (process.browser) {
      this.appendCulqi3DSScript().then(() => {
        this.#publicKey = codigoComercio
        window.Culqi3DS.publicKey = this.#publicKey
        window.Culqi3DS.settings = {
          charge: {
            totalAmount: settings.amount,
            returnUrl: settings.url
          },
          card: {
            email: settings.email
          }
        }
        Culqi3DS.options = {
          showModal: true,
          showLoading: true,
          showIcon: true,
          closeModalAction: function () {
            /* ... */
          },
          style: {
            btnColor: '#3c1361',
            btnTextColor: '#FFFFFF'
          }
        }
      })
    }
  }

  appendCulqi3DSScript () {
    return new Promise((resolve) => {
      let c = 0
      if (!document.getElementById('culqi-lib-3ds')) {
        let culqi3DSScript = document.createElement('script')
        culqi3DSScript.setAttribute('src', baseURLCulqi3DS)
        culqi3DSScript.setAttribute('id', 'culqi-lib-3ds')
        document.body.appendChild(culqi3DSScript)
      }
      let checkCulqi3DS = setInterval(() => {
        c++
        if (c > 10) clearInterval(checkCulqi3DS) // si hace más de 10 intentos termina la verificación de Culqi
        if (window.Culqi3DS) {
          clearInterval(checkCulqi3DS)
          resolve()
        }
      }, 1000)
    })
  }

  mounted (funcionMessage) {
    window.addEventListener('message', funcionMessage, false)
  }

  async getDeviceIdCulqi3DS () {
    const deviceId = await window.Culqi3DS.generateDevice()
    return deviceId
  }

  initCulqi3DS (tokenId) {
    window.Culqi3DS.initAuthentication(tokenId)
  }

  beforeDestroy () {
    window.removeEventListener('message', this.handleMessage, false)
  }

  reset () {
    window.Culqi3DS.reset()
  }
}
