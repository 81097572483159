<template>
  <div :class="['form__row', {dobble: mdDobble}]">
    <div class="form__row__col">
      <div class="form__group">
        <label class="label-text" :for="id">{{ label }}</label>
        <div :class="['form__group-input']">
          <input
            :id="id"
            :type="type"
            :name="nameInput"
            :maxlength="maxlength"
            :data-culqi="mdDataCulqi"
            v-model="textValue"
            :class="checked"
            :ref="nameInput !== '' ? nameInput : id"
            v-on:focus="onFocus"
            v-on:blur="onBlur"
            @keypress="regexKeypress($event)"
            @paste="onPaste"
            @input="updateSelf($event.target)"
            v-maska:[maskaOptions]
            :placeholder="mdPlaceholder"
            :disabled="mdInputDisabled"
          />

          <div v-if="mdIcon" class="brand" :class="branIcon"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { MASKA_OPTIONS } from '@src/utils/constants'
export default {
  name: 'input-component',
  props: {
    mdInputDisabled: {
      type: Boolean,
      default: false
    },
    mdDataCulqi: {
      type: String,
      default: 'data-culqi'
    },
    mdDobble: {
      type: Boolean,
      default: false
    },
    mdIcon: {
      type: Boolean,
      default: false
    },
    mdIconClass: {
      type: String,
      default: ''
    },
    mdPlaceholder: {
      type: String,
      default: ''
    },
    regex: {
      type: [RegExp, String]
    },
    nameInput: {
      type: String,
      default: ''
    },
    label: String,
    inputValue: String,
    errorMsg: String,
    handleBlur: Function,
    starIcon: {
      type: HTMLElement,
      default: null
    },
    maska: {
      type: String,
      default: ''
    },
    endIcon: {
      type: HTMLElement,
      default: null
    },
    maskLimiter: {
      type: String,
      default: ''
    },
    maskGroup: {
      type: String,
      default: ''
    },
    mask: {
      type: String,
      default: ''
    },
    maskGroupLimit: {
      type: String,
      default: ''
    },
    handleFocus: Function,
    onChangeInput: Function,
    checked: {
      type: [String, Boolean],
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    id: {
      type: String,
      default: 'idInput'
    },
    mdRef: {
      type: String,
      default: ''
    },
    maxlength: [String, Number],
    'data-culqi': String
  },
  data: () => {
    return {
      textValue: '',
      isFocus: false
    }
  },
  mounted () {
    this.textValue = this.inputValue
  },
  methods: {
    onPaste (e) {
      if (this.regex) {
        const valid = new RegExp(this.regex)
        const value = e.clipboardData.getData('text').split('')
        const newValue = value.filter((item) => !valid.test(item)).join('')
        this.textValue = newValue
        this.onChangeInput(newValue, e.target.name)
      }
    },
    regexKeypress (e) {
      const { key } = e
      if (this.regex) {
        const valid = new RegExp(this.regex)
        if (!valid.test(key)) {
          e.preventDefault()
        }
      }
    },
    onFocus (e) {
      this.handleFocus(e)
    },
    onBlur (e) {
      this.handleBlur(e)
    },
    updateSelf: async function (e) {
      const { name, value } = e
      this.textValue = value
      await this.onChangeInput(value, name)
    }
  },
  computed: {
    branIcon () {
      if (this.mdIconClass === 'diners_carte_blanche') {
        return 'diners'
      }
      return this.mdIconClass
    },
    maskaOptions () {
      return MASKA_OPTIONS[this.maska]
    }
  }
}
</script>

<style lang="scss" scope>
@import '@assets/scss/config/_palette.scss';

.form__group {
  .invalid {
    border-color: $red !important;
  }
  .valid {
    border-color: $green !important;
  }
}
</style>
