<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div class="modal animate__animated animate__bounceIn" role="dialog">
        <header class="modal-header" id="modalTitle">
          <img src="../../static/img/icon5.svg" alt="">
        </header>

        <section class="modal-body" id="modalDescription">
          <h3 class="title">{{ title }}</h3>
          <p class="text">{{ text }}</p>
        </section>
        <footer class="modal-footer">
          <button
            type="button"
            class="btn-danger"
            @click="reload"
            aria-label="Close modal"
          >
            Aceptar
          </button>
        </footer>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Modal',
  props: ['title', 'text', 'reloadEnabled'],
  methods: {
    close () {
      this.$emit('close')
    },
    reload () {
      if (this.reloadEnabled) {
        window.location.reload(true)
      } else {
        this.close()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@assets/scss/config/_.scss';

.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999999999;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;

  .modal {
    background: $white;
      padding: 20px 38px 20px 38px;

    @media screen and (max-width: $screen-sm-min) {
      padding: 20px 20px 20px 20px;
    }

    margin-left: 28px;
    max-width: 297px;
    margin-right: 28px;
    border-radius: 4px;

    .modal-header {
      display: flex;
      justify-content: center;
      margin-top: 3px;
      margin-bottom: 25px;
    }

    .modal-body {
      margin-bottom: 32px;

      .text {
        font-size: 14.22px;
        color: $graySecondary;
        margin: 0px;
        text-align: center;
      }

      .title {
        font-size: 18px;
        color: $black;
        margin:9px 0px;
        font-weight: 600;
        text-align: center;
        line-height: 28px;
      }
    }

    .modal-footer {
      flex-direction: column;
      display: flex;

      .btn-danger {
        padding: 10px;
        color: white;
        background: $error;
        border: 1px solid $error;
        font-size: 14.22px;
        font-weight: bold;
        border-radius: 4px;
        line-height: 20px;
      }
    }
  }
}

.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}
</style>
