import ApiConnectorUtil from '../utils/api-connector'
import { setHeaders, setHeadersWithOutAuthorization, showNotifyError } from '../utils/functions'
import { ERROR_MESSAGE } from '../utils/constants.js'

const baseURLBackendExpress = process.env.VUE_APP_BACKEND_EXPRESS_URL
// const baseURLBackendSession = process.env.VUE_APP_BACKEND_SESSION

export default {
  async getLinkInfo (payload, sessionId) {
    const apiConnectorUtil = new ApiConnectorUtil()
    const header = await setHeadersWithOutAuthorization()
    return apiConnectorUtil.post(
      baseURLBackendExpress,
      '/express/public/payment/getLinkInfo',
      payload,
      header
    )
  },
  async createOrderWithOutConfirm (pk, payload) {
    try {
      const apiConnectorUtil = new ApiConnectorUtil()

      return await apiConnectorUtil.post(
        baseURLBackendExpress,
        '/express/public/payment/generateOrderWithOutConfirm',
        payload,
        await setHeaders(pk)
      )
    } catch (error) {
      if (error.response) {
        return error.response.data
      }
    }
  },
  async createCharge (pk, payload) {
    try {
      const apiConnectorUtil = new ApiConnectorUtil()
      const headers = await setHeaders(pk)
      const response = await apiConnectorUtil.post(
        baseURLBackendExpress,
        '/express/public/payment/generateCharge',
        payload,
        headers
      )
      if (response?.data?.object === 'error') {
        showNotifyError(response.data.user_message)
      }
      return response
    } catch (error) {
      if (error?.response) {
        showNotifyError(ERROR_MESSAGE.CHARGE_NOT_FOUND.text)
        return error.response.data
      }
    }
  },
  async updateOrderAndTransaction (pk, payload) {
    try {
      const apiConnectorUtil = new ApiConnectorUtil()

      const response = await apiConnectorUtil.post(
        baseURLBackendExpress,
        '/express/public/payment/createOrderTransaction',
        payload,
        await setHeaders(pk)
      )
      if (response?.data?.object === 'error') {
        showNotifyError(response.data.user_message)
      }
      return response
    } catch (error) {
      if (error?.response) {
        showNotifyError(ERROR_MESSAGE.ORDER_GENRATE_TRANSACTION_NOT_FOUND.text)
        return error.response.data
      }
    }
  },

  async updatePaymentType (pk, payload) {
    try {
      const apiConnectorUtil = new ApiConnectorUtil()

      const response = await apiConnectorUtil.post(
        baseURLBackendExpress,
        'express/public/payment/updatePaymentType',
        payload,
        await setHeaders(pk)
      )
      if (response?.data?.object === 'error') {
        showNotifyError(response.data.user_message)
      }
      return response
    } catch (error) {
      if (error?.response) {
        showNotifyError(ERROR_MESSAGE.UPDATE_PAYMENT_TYPE_NOT_FOUND.text)
        return error.response.data
      }
    }
  }
/*
  async getSessionExpress (auth) {
    try {
      const apiConnectorUtil = new ApiConnectorUtil()

      const response = await apiConnectorUtil.get(
        baseURLBackendSession,
        'session/get-session?app=express',
        {
          Authorization: `${AUTHORIZATION.BEARER} ${auth}`
        }
      )
      return response
    } catch (error) {
      if (error?.response) {
        return error.response.data
      }
    }
  }
  */
}
