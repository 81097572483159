import crypto from 'crypto-browserify'
import { CONFIG_ENCRYPT } from './constants'

const stringToArrayBuffer = (str) => {
  const bufView = new Uint8Array([...str].map((char) => char.charCodeAt(0)))
  return bufView.buffer
}

const arrayBufferToString = (buf) => {
  const uint8Array = new Uint8Array(buf)
  const array = Array.from(uint8Array)
  return String.fromCharCode.apply(null, array)
}

const importRsaPublicKey = async (publicKey) => {
  const pemHeader = CONFIG_ENCRYPT.pem.header
  const pemFooter = CONFIG_ENCRYPT.pem.footer
  const pemContents = publicKey.substring(
    pemHeader.length,
    publicKey.length - pemFooter.length
  )
  const binaryDerString = window.atob(pemContents)
  const binaryDer = stringToArrayBuffer(binaryDerString)

  return window.crypto.subtle.importKey(
    CONFIG_ENCRYPT.format,
    binaryDer,
    {
      name: CONFIG_ENCRYPT.name,
      hash: CONFIG_ENCRYPT.hash
    },
    false,
    CONFIG_ENCRYPT.keyUsasge
  )
}

const subtleEncrypt = async (publicKey, value) =>
  window.crypto.subtle.encrypt(
    {
      name: CONFIG_ENCRYPT.name
    },
    publicKey,
    value
  )

export const encryptWithAESRSA02 = async (data, publicKey) => {
  const publicKeyFormated = await importRsaPublicKey(publicKey)

  const key = crypto.randomBytes(32)
  const iv = crypto.randomBytes(16)

  const cipher = crypto.createCipheriv(CONFIG_ENCRYPT.algorithm, key, iv)

  let encrypted

  encrypted = cipher.update(
    data,
    CONFIG_ENCRYPT.inputEncoding,
    CONFIG_ENCRYPT.outputEncoding
  )

  encrypted += cipher.final(CONFIG_ENCRYPT.outputEncoding)

  const tag = cipher.getAuthTag()

  const encryptedTag = await subtleEncrypt(publicKeyFormated, tag)

  const encryptedKey = await subtleEncrypt(publicKeyFormated, key)
  const encryptedIv = await subtleEncrypt(publicKeyFormated, iv)

  const encryptedKeyBase64 = window.btoa(arrayBufferToString(encryptedKey))
  const encryptedIvBase64 = window.btoa(arrayBufferToString(encryptedIv))
  const encryptTagBase64 = window.btoa(arrayBufferToString(encryptedTag))

  return {
    encryptedData: encrypted,
    encryptedKey: encryptedKeyBase64,
    encryptedIv: encryptedIvBase64,
    tag: encryptTagBase64
  }
}
