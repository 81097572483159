const baseURLCulqiSecure = process.env.VUE_APP_CHECKOUT_URL

export default class Culqi {
  #publicKey

  constructor ({ codigoComercio, settingsParams, paymentMethods, clientParam }) {
    if (process.browser && paymentMethods) {
      this.appendCulqiScript().then(() => {
        this.#publicKey = codigoComercio
        const settings = {
          title: settingsParams.comerceName,
          currency: settingsParams.currency,
          amount: settingsParams.amount,
          order: settingsParams.orderId
          // xculqiproduct: 'express'
        }
        const client = {
          email: clientParam.email
        }
        const options = {
          lang: 'es',
          installments: true,
          modal: true,
          // container: '#culqi-container',
          paymentMethods,
          paymentMethodsSort: Object.keys(paymentMethods)
        }
        const appearance = {
          theme: 'default',
          hiddenCulqiLogo: false,
          hiddenBannerContent: false,
          hiddenBanner: false,
          hiddenToolBarAmount: false,
          menuType: 'sidebar', // sidebar / sliderTop / select
          // buttonCardPayText: 'Pagar tal monto', //
          logo: settingsParams.style.logo,

          defaultStyle: {
            bannerColor: settingsParams.style.bannerColor,
            buttonBackground: settingsParams.style.buttonBackground, // hexadecimal
            menuColor: settingsParams.style.menuColor, // hexadecimal
            linksColor: settingsParams.style.linksColor, // hexadecimal
            buttonTextColor: settingsParams.style.buttonTextColor, // hexadecimal
            priceColor: settingsParams.style.priceColor
          }
        }
        const config = {
          settings,
          options,
          client,
          appearance
        }
        window.Culqi = new window.CulqiCheckout(this.#publicKey, config)
      })
    }
  }

  appendCulqiScript () {
    return new Promise((resolve) => {
      let c = 0
      if (!document.getElementById('culqi-lib')) {
        let culqiScript = document.createElement('script')
        culqiScript.setAttribute('src', baseURLCulqiSecure)
        culqiScript.setAttribute('id', 'culqi-lib')
        document.body.appendChild(culqiScript)
      }
      let checkCulqi = setInterval(() => {
        c++
        if (c > 10) clearInterval(checkCulqi) // si hace más de 10 intentos termina la verificación de Culqi
        if (window.CulqiCheckout) {
          clearInterval(checkCulqi)
          resolve()
        }
      }, 1000)
    })
  }

  open () {
    return new Promise((resolve, reject) => {
      let c = 0
      let checkSettingCulqi = setInterval(() => {
        c++
        if (c > 10) {
          clearInterval(checkSettingCulqi)
          reject(new Error('Superó el límite de verificaciones'))
        }
        if (window.Culqi?.getSettings()) {
          clearInterval(checkSettingCulqi)
          window.Culqi.open()
          resolve()
        }
      }, 1000)
    })
  }

  close () {
    window.Culqi.close()
  }

  destroy () {
    document.getElementById('culqi-lib').remove()
    window.Culqi = null
  }

  getToken () {
    return window.Culqi?.token ?? null
  }

  getOrder () {
    return window.Culqi?.order ?? null
  }

  getError () {
    return window.Culqi?.error ?? null
  }
}
