<template>
  <div class="header" v-if="isMobile && isMenuPage">
    <div class="header__content">
      <div class="header__logo">
        <img :src="merchantLogo" alt="Culqi" />
      </div>
      <h4 class="header__title">{{ mdStore }}</h4>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header',
  props: {
    mdConcept: {
      type: String,
      default: ''
    },
    mdAmount: {
      type: [String, Number],
      default: ''
    },
    mdStore: {
      type: String,
      default: ''
    },
    mdLogo: {
      type: String,
      default: 'https://static.culqi.com/v4/v4-checkout/brand.svg'
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    mdCurrentPage: {
      type: String,
      default: ''
    }
  },
  computed: {
    isMenuPage () {
      return this.mdCurrentPage === 'cardForm' || this.mdCurrentPage === 'cipView'
    },
    merchantLogo () {
      const logo = this.mdLogo !== '' && this.mdLogo !== null
      return logo ? this.mdLogo : 'https://static.culqi.com/v4/v4-checkout/brand.svg'
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@assets/scss/config/_palette.scss';

.header {
  height: 58px;
  width: 100%;
  background: $black;
  padding: 0 19px 16px;
  &__content {
    width: 100%;
    display: flex;
    align-items: center;
  }
  &__logo {
    width: 48px;
    height: 48px;
    border-radius: 3px;
    overflow: hidden;
    background: $white;
    img {
      object-fit: contain;
      width: 100%;
    }
  }
  &__title {
    margin-left: 16px;
    font-size: 18px;
    font-weight: 600;
    color: $white;
  }
}
</style>
