<template>
  <div :class="mdClassContainer" class="container-amount">
    <div class="container-amount_head">
      <h3 class="container-amount_head-title">Detalle de la operación</h3>
      <p class="container-amount_head-subtitle">{{ mdConcept }}</p>
    </div>
    <hr />
    <div class="form">
      <InputDecimal
        v-model="inputValue"
        :md-input-disabled="mdInputDisabled"
        :md-prefix="mdPrefix"
        :md-class="classInputCustom"
        md-label="Ingresa el monto"
        md-id="amount"
        name-input="amount"
        @amountValue="amountValue"
        :md-min-amount="mdMinAmount"
        :md-max-amount="mdMaxAmount"
      />
    </div>
    <span v-if="isErrorMessage" class="message-error">Este monto está fuera del rango establecido.</span>
    <span class="message-antifraud">{{
      `Puedes ingresar un monto entre ${this.formatAmount(
        this.mdMinAmount
      )} y ${this.formatAmount(this.mdMaxAmount)}`
    }}</span>
  </div>
</template>

<script>
import InputDecimal from '@components/Atoms/InputDecimal.vue'
import { formaAmount, formatAmountToDecimal } from '@src/utils/functions'

export default {
  name: 'TableContainerAmount',
  components: { InputDecimal },
  props: {
    mdConcept: {
      type: String,
      default: ''
    },
    mdClassContainer: {
      type: String,
      default: ''
    },
    mdInputDisabled: {
      type: Boolean,
      default: false
    },
    mdPrefix: {
      type: String,
      required: true
    },
    mdCurrency: {
      type: String,
      required: true
    },
    mdMinAmount: {
      type: Number,
      required: true
    },
    mdMaxAmount: {
      type: Number,
      required: true
    }
  },
  data: () => {
    return {
      checked: {
        amount: ''
      },
      amount: '',
      isNotValueEmpty: true,
      inputValue: ''
    }
  },
  watch: {
    amount (value) {
      this.$emit('amount', { amount: value, checked: value !== 0 ? this.checked.amount : false })
    }
  },
  methods: {
    amountValue ({ value, isValid, isNotValueEmpty }) {
      this.amount = formaAmount(value)
      this.checked.amount = isValid
      this.isNotValueEmpty = isNotValueEmpty
    },
    formatAmount (value) {
      return `${this.mdPrefix}${formatAmountToDecimal(value)}`
    }
  },
  computed: {
    classInputCustom () {
      return this.mdCurrency === 'PEN' ? 'prefix-padding' : ''
    },
    isErrorMessage () {
      if (
        this.isNotValueEmpty &&
        !this.checked.amount &&
        this.checked.amount !== ''
      ) {
        return true
      }
      return false
    }
  }
}
</script>

<style lang="scss" scope>
@import '@assets/scss/config/_palette.scss';

.container-amount {
  &_head {
    color: $black-light;
    padding-bottom: 2px;
    &-title {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 8px;
    }
    &-subtitle {
      font-size: 14px;
      overflow: hidden;
      padding-right: 5px;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
.message-antifraud,
.message-error {
  margin-left: 5px;
  display: block;
  color: #4e5d73;
  font-weight: 300 !important;
  font-style: normal;
  font-size: 11px !important;
}
.message-error {
    color: $error !important;
    margin-bottom: 8px !important;
}
</style>
