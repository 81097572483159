/* eslint-disable */
// import Vue from 'vue/dist/vue.min.js';
import Vue from 'vue';
import App from './App.vue';
import Notify from "notify-zh";
import { vMaska } from "maska";
import './assets/scss/app.scss';
import './assets/scss/config/_.scss';
import 'tippy.js/dist/tippy.css';
import '@/assets/css/1.main.css';
import router from './router'
import VueConfetti from 'vue-confetti'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

const NotifyPlugin = {
  install() {
    Vue.notify = Notify;
    Vue.prototype.$notify = Notify;
  }
};
const options = {
  closeOnClick: false,
  pauseOnFocusLoss: false,
  pauseOnHover: false,
  draggable: true,
  draggablePercent: 1.37,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: false,
  icon: true,
  rtl: false,
  transition: 'Vue-Toastification__fade',
  maxToasts: 5,
  newestOnTop: true
};

Vue.use(Toast, options)
Vue.use(NotifyPlugin);
Vue.use(VueConfetti)
Vue.directive("maska", vMaska);

Vue.config.devtools = process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'local';
Vue.config.productionTip = false;

new Vue({
  router,
  components: { App },
  render: h=> h(App)
}).$mount('#app');
