import { render, staticRenderFns } from "./InitialPage.vue?vue&type=template&id=40fea8fa&scoped=true"
import script from "./InitialPage.vue?vue&type=script&lang=js"
export * from "./InitialPage.vue?vue&type=script&lang=js"
import style0 from "./InitialPage.vue?vue&type=style&index=0&id=40fea8fa&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40fea8fa",
  null
  
)

export default component.exports