<template>
  <div :class="mdClassContainer">
    <table :class="[mdClassTable, 'table']">
      <thead v-if="isHeaderPresent">
        <tr>
          <th
            v-for="(header, index) in mdHeader"
            :key="index"
            :colspan="header.colspan ? header.colspan : 1"
          >
            {{ header.text }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(tr, index) in mdTrList" :key="index" :class="tr.class ? tr.class : ''">
          <td
            v-for="(td, tdIndex) in tr.items"
            :key="tdIndex"
            :colspan="td.colspan ? td.colspan : 1"
          >
            {{ td.text }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'TableContainer',
  props: {
    mdHeader: {
      type: Array,
      default: () => []
    },
    isHeaderPresent: {
      type: Boolean,
      default: true
    },
    mdClassContainer: {
      type: String,
      default: ''
    },
    mdClassTable: {
      type: String,
      default: ''
    },
    mdClassBodyTr: {
      type: String,
      default: ''
    },
    mdTrList: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="scss" scope>
@import '@assets/scss/config/_palette.scss';

table {
  font-family: $FontLexend;
  width: 100%;
  table-layout: fixed;
}
tbody {
  td {
    padding: 15px 0;
    font-size: 14px;
  }
}
</style>
