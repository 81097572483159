<template>
  <div>
    <p class="initialpage-info">Completa tus datos para continuar con el pago</p>
    <form class="form" @submit.prevent="submitMethod">
      <Input
        type="text"
        id="name"
        :inputValue="name"
        :handleBlur="blurCheckInput"
        nameInput="name"
        label="Nombre"
        maska="TEXT"
        maxlength="30"
        :checked="checked.name"
        :errorMsg="validationMsg.name"
        :handleFocus="focusInput"
        :onChangeInput="onChangeInput"
        :md-dobble="true"
        :md-input-disabled="mdInputDisabled"
      />

      <Input
        type="text"
        id="lastname"
        label="Apellidos"
        :inputValue="lastname"
        maxlength="30"
        :handleBlur="blurCheckInput"
        nameInput="lastname"
        maska="TEXT"
        :checked="checked.lastname"
        :errorMsg="validationMsg.lastname"
        :handleFocus="focusInput"
        :onChangeInput="onChangeInput"
        :md-dobble="true"
        :md-input-disabled="mdInputDisabled"
      />

      <Input
        id="email"
        :inputValue="email"
        type="email"
        nameInput="email"
        maxlength="50"
        :handleBlur="blurCheckInput"
        :handleFocus="focusInput"
        :onChangeInput="onChangeInput"
        label="Correo electrónico"
        maska="EMAIL"
        :checked="checked.email"
        :errorMsg="validationMsg.email"
        :md-dobble="true"
        md-placeholder="correo@electronico.com"
        :md-input-disabled="mdInputDisabled"
      />

      <Input
        id="tel"
        :inputValue="tel"
        :handleBlur="blurCheckInput"
        nameInput="tel"
        type="tel"
        :handleFocus="focusInput"
        regex="^[0-9]*$"
        :onChangeInput="onChangeInput"
        label="Celular"
        :checked="checked.tel"
        :errorMsg="validationMsg.tel"
        :md-dobble="true"
        maska="ALL_PHONE"
        md-placeholder="000000000000"
        :md-input-disabled="mdInputDisabled"
      />
      <div class="form__row dobble">
        <div class="form__row__col">
          <div class="form__group">
            <button-single
              md-text="Continuar"
              :validForm="validForm && !mdInputDisabled"
              @click-button="submitMethod"
            />
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Input from '@components/Input.vue'
import ButtonSingle from '@components/Atoms/ButtonSingle.vue'
import * as validations from '@src/utils/validations'

export default {
  name: 'inital-page',
  components: { Input, ButtonSingle },
  beforeMount () {
    this.email = this.defaultClientEmail
  },
  data () {
    return {
      name: '',
      tel: '',
      email: '',
      lastname: '',
      order: '',
      validationMsg: {
        name: '',
        email: '',
        tel: '',
        lastname: ''
      },
      checked: {
        name: false,
        email: false,
        tel: false,
        lastname: false,
        order: true
      }
    }
  },
  props: {
    defaultClientEmail: String,
    amount: String,
    currency: String,
    currencySymbol: String,
    mdInputDisabled: {
      type: Boolean,
      default: false
    },
    mdAmoutOpenField: {
      type: Object,
      default: () => ({
        isValid: false,
        isRequired: false
      })
    }
  },
  methods: {
    focusInput: function (e) {
      const { name } = e.target
      this.validationMsg[name] = ''
    },
    functionRegexValidation (type, value) {
      switch (type) {
        case 'email':
          return validations.validateEmail(value)
        case 'name':
        case 'lastname':
          return validations.validateText(value)
        case 'tel':
          return validations.validatePhone(value.replace(/ /g, ''))
        case 'order':
          return validations.validateTextAlphanumeric(value.replace(/ /g, ''))
        default:
          return false
      }
    },
    validInput: function (type, value) {
      const isValid = this.functionRegexValidation(type, value)
      if (!value) {
        this.checked[type] = ''
      } else if (!isValid) {
        this.checked[type] = 'invalid'
      } else {
        this.checked[type] = 'valid'
      }
    },
    blurCheckInput: function (e) {
      if (e) {
        const { value, name } = e.target
        this.validInput(name, value)
      }
    },
    onChangeInput: function (val, name) {
      this[name] = val
      this.validInput(name, val)
    },
    submitMethod: function () {
      const { email, tel, name, lastname, order } = this
      this.$emit('submitMethod', { email, tel, name, lastname, order })
    }
  },
  computed: {
    validForm () {
      const isValid = 'valid'
      if (this.mdAmoutOpenField.isRequired && !this.mdAmoutOpenField.isValid) {
        return false
      }
      return Boolean(
        this.checked.name === isValid &&
        this.checked.tel === isValid &&
        this.checked.email === isValid &&
        this.checked.lastname === isValid
      )
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@assets/scss/config/_.scss';

.initialpage-info {
  color: $black-light;
  font-weight: 400;
  font-size: 14px;
  margin-top: 24px;
  @include from(600px) {
    margin-top: 52px;
  }
  margin-bottom: 24px;
}
.two-input{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
  div {
    width: 100%;
  }
  @include mq('tablet', max) {
    display: block;
  }
}
.placeholderColor {
  color: red !important;
}

.btt-page1 {
  margin-top: 24px;
  margin-bottom: 15px;
}
.form__row.dobble{
  .btn.btn-green{
    margin-top:16px;
  }
}
</style>
