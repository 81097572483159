<template>
  <div
    v-show='mdShow'
    class='spinner-sector'
    :class='`spinner-sector-${mdClassColor}`'
  ></div>
</template>

<script>
export default {
  name: 'SpinnerVector',
  props: {
    mdShow: {
      type: [Boolean, Number],
      default: false
    },
    mdClassColor: {
      type: String,
      default: ''
    }
  }
}
</script>
