<template>
  <button
    @click="$emit('click-button')"
    :class="['btn btn-green', mdClass]"
    :disabled="!validForm"
  >
    {{ mdText }} {{ mdAmount ? `${mdAmount}` : '' }}
  </button>
</template>

<script>
export default {
  name: 'ButtonSingle',
  props: {
    mdClass: {
      type: String,
      default: ''
    },
    mdText: {
      type: String,
      default: ''
    },
    mdAmount: {
      type: [Number, String],
      default: 0
    },
    validForm: {
      type: Boolean,
      default: false
    }
  },
  data: () => {
    return {}
  }
}
</script>
