import { REGEX_AND_VALUES } from './constants'
import { getCurrentDate } from './functions'

export const luhnCheck = (num) => {
  const digits = num.split('').reverse()
  let isSecond = false
  let nSum = 0
  const result = digits.reduce((acc, current) => {
    if (isSecond === true) current = current * 2
    nSum += parseInt(current / 10, 10)
    nSum += current % 10
    isSecond = !isSecond
    return nSum
  }, 0)
  return result % 10 === 0
}

export const parseNumber = (cardNumber) => {
  const tcard = cardNumber.replace(/\s/g, '')
  const firstSix = tcard.substring(0, 1)
  const cardRegex = REGEX_AND_VALUES.CARDNUMBER
  let length = 16
  let maskLength = 16
  let mask = '#### #### #### ####'
  // eslint-disable-next-line eqeqeq
  if (firstSix != '0' && firstSix != '1') {
    if (tcard !== '') {
      for (const type in cardRegex) {
        if (tcard.match(cardRegex[type].num) != null) {
          length = cardRegex[type].length
          maskLength = cardRegex[type].maskLength
          mask = cardRegex[type].mask
          return { type: type.toLowerCase(), length, maskLength, mask }
        }
      }
    }
  }
  return { type: null, length, maskLength }
}

export const validateCardNumber = (cardNumber) => {
  const tcard = cardNumber.replace(/\s/g, '')
  const firstSix = tcard.substring(0, 1)
  // eslint-disable-next-line eqeqeq
  if (firstSix == '0' || firstSix == '1') {
    return false
  }
  return luhnCheck(tcard)
}

export const validatePhone = (phone) => {
  return REGEX_AND_VALUES.PHONE.test(phone)
}

export const validateEmail = (email) => {
  return REGEX_AND_VALUES.EMAIL.test(email)
}

export const validateCvv = (cvv) => {
  return REGEX_AND_VALUES.CVV.test(cvv)
}

export const validateText = (text) => {
  return REGEX_AND_VALUES.TEXT.test(text)
}

export const validateExpiry = (date) => {
  let isValid = true
  const { month, year } = getCurrentDate()
  const expiryArray = date.split('/')
  const expiryMonth = parseInt(expiryArray[0], 10)
  const expiryYear = parseInt('20' + expiryArray[1], 10)
  if (expiryYear >= year) {
    // eslint-disable-next-line eqeqeq
    if (expiryYear == year && month > expiryMonth) {
      isValid = false
    }
  } else {
    isValid = false
  }
  return isValid
}

export const validateTextAlphanumeric = (text) => {
  return REGEX_AND_VALUES.TEXT_ALFA_NUM.test(text)
}

export const validateNumber = (number, different) => {
  if (number !== different) {
    return REGEX_AND_VALUES.NUMERIC.test(number)
  }
  return false
}

export const validateDecimal = (decimal) => {
  return REGEX_AND_VALUES.DECIMAL.test(decimal)
}

export const validateDecimalIRange = (decimal, min, max) => {
  if (!validateDecimal(decimal)) return { success: false, type: 'format' }

  const parsedDecimal = parseFloat(decimal)
  const isValidRange = parsedDecimal >= min && parsedDecimal <= max
  if (!isValidRange) return { success: false, type: 'range' }
  return { success: true }
}
