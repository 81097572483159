<template>
  <div class="loading-page" v-if="show">
    <!-- <div class=""> -->
    <!-- <div class="loader">Loading...</div> -->
    <div class="spinner">
      <div class="spinner-text">
        <img
          src="/img/yape/yape_logo.svg"
          class="logo"
        />
      </div>
      <spinner-vector
        v-for="color in colorsSpinner"
        :key="color.id"
        :md-show="show"
        :md-class-color="color.color"
      />
    </div>
    <!-- </div> -->
  </div>
</template>

<script>
import SpinnerVector from '@components/Atoms/SpinnerVector.vue'

export default {
  name: 'loader',
  components: {
    SpinnerVector
  },
  props: {
    show: Boolean,
    colorsSpinner: Array
  }
}
</script>

<style lang="scss" scoped>
@import "@assets/scss/config/_animations.scss";
@import '@assets/scss/config/_palette.scss';

.loading-page {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.64);
  z-index: 999999999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 150px;
  &-sector {
    border-radius: 50%;
    position: absolute;
    width: 50%;
    height: 50%;
    border: 7px solid transparent;
    /*mix-blend-mode: overlay;*/
  }
  &-text {
    width: 40%;
    max-width: 65px;
    height: 30%;
    animation: loading-opacity 2s ease-in-out infinite;
    font-size: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    .logo{
      width: 100px;
      height: 100px;
    }
  }
  &-sector-purple {
    animation: rotate 2s ease-out infinite;
    border-top: 7px solid $purple;
  }

  &-sector-green {
    animation: rotate 1.5s ease-in-out infinite;
    border-top: 7px solid $green;
  }

  &-sector-orange {
    animation: rotate 3s ease-in-out infinite;
    border-top: 7px solid $orange;
  }
  &-sector-purple-1 {
    animation: rotate 2s ease-out infinite;
    border-top: 7px solid $purple-yape;
  }
  &-sector-purple-2 {
    animation: rotate 1.5s ease-out infinite;
    border-top: 7px solid $purple-yape;
  }
 &-sector-purple-3 {
    animation: rotate 3s ease-out infinite;
    border-top: 7px solid $purple-yape;
  }

}

</style>
