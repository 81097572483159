<template>
  <CheckoutYape/>
</template>

<script>
import CheckoutYape from '@pages/yape/CheckoutYape.vue'

export default {
  name: 'app',
  components: {
    CheckoutYape
  }
}
</script>

<style lang="scss">
@import '@assets/scss/checkout.scss';
</style>
