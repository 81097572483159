var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"success-payment"},[_c('div',{staticClass:"voucher"},[_c('div',{staticClass:"voucher__info"},[_c('div',{staticClass:"voucher__info-top"},[_c('svg',{attrs:{"width":"41","height":"41","viewBox":"0 0 41 41","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"fill-rule":"evenodd","clip-rule":"evenodd","d":"M2.27087 20.142C2.27087 10.2469 10.2924 2.22534 20.1875 2.22534C30.0826 2.22534 38.1042 10.2469 38.1042 20.142C38.1042 30.0371 30.0826 38.0587 20.1875 38.0587C10.2924 38.0587 2.27087 30.0371 2.27087 20.142ZM20.1875 4.72534C11.6732 4.72534 4.77087 11.6276 4.77087 20.142C4.77087 28.6564 11.6732 35.5587 20.1875 35.5587C28.7019 35.5587 35.6042 28.6564 35.6042 20.142C35.6042 11.6276 28.7019 4.72534 20.1875 4.72534ZM27.6216 14.1553C28.1666 14.5792 28.2647 15.3645 27.8409 15.9094L21.1626 24.4959C20.123 25.8324 18.1677 26.0058 16.9091 24.8731L12.6847 21.0711C12.1715 20.6093 12.1299 19.8189 12.5918 19.3058C13.0536 18.7927 13.8439 18.7511 14.3571 19.2129L18.5815 23.0149C18.7613 23.1767 19.0407 23.152 19.1892 22.961L25.8675 14.3746C26.2914 13.8296 27.0767 13.7315 27.6216 14.1553Z","fill":"#742384"}})]),_c('h3',{staticClass:"yape"},[_vm._v("Pago exitoso")])]),_c('div',{staticClass:"voucher__info-bottom"},[_c('table-container',{attrs:{"md-class-container":"voucher__table-container","md-class-table":"detail","is-header-present":false,"md-tr-list":[
            {
              items: [
                {text: 'Nº de Tarjeta', colspan: '2'},
                {text: `****${_vm.formatCardNumber}`}
              ]
            },
            {
              items: [{text: 'Fecha', colspan: '2'}, {text: _vm.paymentDate }]
            },
            {
              class: 'voucher-total',
              items: [{text: 'Total', colspan: '2'}, {text: _vm.mdAmount}]
            }
          ]}})],1)]),_c('div',{staticClass:"voucher__text"},[_c('span',[_c('strong',[_vm._v("“"+_vm._s(_vm.mdStore)+"”")]),_vm._v(" ha sido notificado de tu pago y hemos enviado el comprobante a tu correo. ")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }