<template>
  <div class="store-amount" v-if="isMenuPage">
    <div class="store-amount__store">
      <h2>{{ isMobile ? 'Monto a pagar' : mdStore }}</h2>
    </div>
    <div class="store-amount__amount">
      <h2>{{ mdAmount }}</h2>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1.25 8C1.25 5.37665 3.37665 3.25 6 3.25H18C20.6234 3.25 22.75 5.37665 22.75 8V13C22.75 13.4142 22.4142 13.75 22 13.75C21.5858 13.75 21.25 13.4142 21.25 13V8C21.25 6.20507 19.7949 4.75 18 4.75H6C4.20507 4.75 2.75 6.20507 2.75 8V16C2.75 17.7949 4.20507 19.25 6 19.25H13C13.4142 19.25 13.75 19.5858 13.75 20C13.75 20.4142 13.4142 20.75 13 20.75H6C3.37665 20.75 1.25 18.6234 1.25 16V8Z"
          fill="#00A19B"
        />
        <path
          d="M7 8C7 7.44772 6.55228 7 6 7C5.44772 7 5 7.44772 5 8C5 8.55228 5.44772 9 6 9C6.55228 9 7 8.55228 7 8Z"
          fill="#00A19B"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12 13.25C11.3096 13.25 10.75 12.6904 10.75 12C10.75 11.3096 11.3096 10.75 12 10.75C12.6904 10.75 13.25 11.3096 13.25 12C13.25 12.6904 12.6904 13.25 12 13.25ZM9.25 12C9.25 13.5188 10.4812 14.75 12 14.75C13.5188 14.75 14.75 13.5188 14.75 12C14.75 10.4812 13.5188 9.25 12 9.25C10.4812 9.25 9.25 10.4812 9.25 12Z"
          fill="#00A19B"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M22.4939 15.4356C22.8056 15.7083 22.8372 16.1822 22.5644 16.4939L19.6945 19.7738C19.0778 20.4786 18.0156 20.5729 17.2843 19.9879L15.5315 18.5857C15.208 18.3269 15.1556 17.8549 15.4143 17.5315C15.6731 17.208 16.1451 17.1556 16.4685 17.4143L18.2213 18.8166C18.3258 18.9002 18.4776 18.8867 18.5657 18.786L21.4356 15.5061C21.7083 15.1944 22.1821 15.1628 22.4939 15.4356Z"
          fill="#00A19B"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InfoStoreAmount',
  props: {
    mdStore: {
      type: String,
      default: ''
    },
    mdAmount: {
      type: [String, Number],
      default: 0
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    mdCurrentPage: {
      type: String,
      default: ''
    }
  },
  computed: {
    isMenuPage () {
      return this.mdCurrentPage === 'cardForm' || this.mdCurrentPage === 'cipView'
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@assets/scss/config/_.scss';

.store-amount {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 17px 16px 13px;
  border-bottom: 1px solid #E7E9EC;
  max-width: 708px;
  margin: 0 auto 17px;
  &__store,
  &__amount {
    h2 {
      font-size: 18px;
      font-weight: 500;
    }
  }
  &__store {
    color: $black-light;
  }
  &__amount {
    color: $green;
    display: flex;
    align-items: center;
    h2 {
      margin-right: 11px;
    }
  }
}
</style>
